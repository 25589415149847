<template>
    <div class="page">
        <header>
            <h2>Espace Personnel</h2>
        </header>


        <h3 id="themes">Mes thèmes favoris</h3>

        <pre class="sql-request"><code>SELECT * FROM PlayerThemes WHERE playerId = {{ user?.id }} ORDER BY name ASC;</code></pre>
        <div class="cards" v-if="user?.favoriteThemes">
            <div class="card" v-for="theme in user.favoriteThemes" :key="theme.id"
                :style="{ borderTop: '8px solid ' + colors[theme.id % colors.length] }">
                <div class="card-actions" v-if="user">
                    <button class="btn btn-icon" @click.prevent="like2(theme)"
                        v-if="user.favoriteThemes.find(favoriteTheme => favoriteTheme.id === theme.id)">
                        <span class="material-symbols-outlined without-text">favorite</span>
                    </button>
                    <button class="btn btn-outline btn-icon" @click.prevent="like2(theme)" v-else>
                        <span class="material-symbols-outlined without-text">heart_plus</span>
                    </button>
                </div>
                <div class="card-content">
                    <h3>{{ theme.name }}</h3>
                    <p>{{ theme.description }}</p>
                </div>
            </div>
        </div>


        <h3 id="mechanics">Mes mécaniques favorites</h3>

        <pre class="sql-request"><code>SELECT * FROM PlayerMechanics WHERE playerId = {{ user?.id }} ORDER BY name ASC;</code></pre>
        <div class="cards" v-if="user?.favoriteMechanics">
            <div class="card" v-for="mechanic in user.favoriteMechanics" :key="mechanic.id"
                :style="{ borderTop: '8px solid ' + colors[mechanic.id % colors.length] }">
                <div class="card-actions" v-if="user">
                    <button class="btn btn-icon" @click="like(mechanic)"
                        v-if="user.favoriteMechanics.find(favoriteMechanic => favoriteMechanic.id === mechanic.id)">
                        <span class="material-symbols-outlined without-text">favorite</span>
                    </button>
                    <button class="btn btn-outline btn-icon" @click="like(mechanic)" v-else>
                        <span class="material-symbols-outlined without-text">heart_plus</span>
                    </button>
                </div>
                <div class="card-content">
                    <h3>{{ mechanic.name }}</h3>
                    <p>{{ mechanic.description }}</p>
                </div>
            </div>
        </div>

        <h3 id="reviews">Mes critiques</h3>

        <pre class="sql-request"><code>SELECT * FROM Reviews WHERE playerId = {{ user?.id }};</code></pre>
        <ul v-if="user?.postedReviews">
            <li v-for="review in user.postedReviews" :key="review.id">
                <div class="comment card card-comment-details white z-depth-0" data-id="review.id"
                    data-name="review.name" data-text="review.content">
                    <div class="card-content">
                        <div class="comment-container">
                            <div class="comment-avatar">
                                <img :src="('https://avatars.dicebear.com/api/avataaars/' + review.pseudo + '.svg')"
                                    width="128" :alt="'Photo de profil du joueur ' + review.pseudo">
                            </div>
                            <div class="comment-header">

                                <h4> {{ review.name }} (pseudo : {{ review.pseudo }}) le {{ review.publicationDate }}
                                </h4>

                            </div>
                            <div class="comment-body">
                                {{ review.comment }}
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>

        <h3 id="ratings">Mes avis</h3>

        <pre class="sql-request"><code>SELECT
    Reviews.*,
    ReviewRatings.helpful,
    Games.id AS gameId, Games.name, Games.picture,
    Players.pseudo, Players.firstName, Players.lastName
FROM Reviews
INNER JOIN Players ON Reviews.playerId = Players.id
INNER JOIN Games ON Reviews.gameId = Games.id
INNER JOIN ReviewRatings ON Reviews.id = ReviewRatings.reviewId
WHERE ReviewRatings.playerId = {{ user?.id }}
ORDER BY Reviews.publicationDate DESC</code></pre>
        <ul v-if="user?.ratings">
            <li v-for="rating in user.ratings" :key="rating.id">
                <div class="comment card card-comment-details white z-depth-0" data-id="rating.id"
                    data-name="rating.name" data-text="rating.content">
                    <div class="card-content">
                        <div class="comment-container">
                            <div class="comment-avatar">
                                <img :src="('https://avatars.dicebear.com/api/avataaars/' + rating.pseudo + '.svg')"
                                    width="128" :alt="'Photo de profil du joueur ' + rating.pseudo">
                            </div>
                            <div class="comment-header">
                                <h4> {{ rating.name }} (pseudo : {{ rating.pseudo }}) le {{ rating.publicationDate }}
                                </h4>

                            </div>
                            <div class="comment-body">
                                <span v-if="(rating.helpful == 1)" class="material-symbols-outlined">thumb_up</span>
                                <span v-if="(rating.helpful == 0)" class="material-symbols-outlined">thumb_down</span>
                                {{ rating.comment }}
                            </div>
                        </div>
                    </div>
                </div>
            </li>
        </ul>
    </div>
</template>
  
<script>
import api from '@/api';
import { mapState } from 'vuex';

const NEW_MECHANIC = {
    name: '',
    description: '',
};
const NEW_THEME = {
    name: '',
    description: '',
};

export default {
    name: 'MechanicsView',
    computed: {
        ...mapState([
            'colors',
            'user',
            'mechanics',
            'themes',
            'reviews',

        ])
    },
    data() {
        return {
            newMechanic: { ...NEW_MECHANIC },
            mechanicToDelete: null,
            newTheme: { ...NEW_THEME },
            themeToDelete: null,

        };
    },
    methods: {
        clearForm() {
            this.newMechanic = { ...NEW_MECHANIC };
            this.newTheme = { ...NEW_THEME };
        },

        like(mechanic) {
            if (this.user.favoriteMechanics.find(favoriteMechanic => favoriteMechanic.id === mechanic.id)) {
                api.auth.removeFavoriteMechanic(mechanic.id)
                    .then(() => {
                        this.$notify({
                            type: 'success',
                            title: 'Succès',
                            text: `La mécanique '${mechanic.name}' a bien été retirée de vos favoris.`
                        });
                        this.$store.dispatch('removeFavoriteMechanic', mechanic);
                    })
                    .catch((error) => {
                        this.$notify({
                            title: 'Erreur lors de la suppression de la mécanique des favoris',
                            text: error.message,
                            type: 'error'
                        });
                    });
            } else {
                api.auth.addFavoriteMechanic(mechanic.id)
                    .then(() => {
                        this.$notify({
                            type: 'success',
                            title: 'Succès',
                            text: `La mécanique '${mechanic.name}' a bien été ajoutée à vos favoris.`
                        });
                        this.$store.dispatch('addFavoriteMechanic', mechanic);
                    })
                    .catch((error) => {
                        this.$notify({
                            title: 'Erreur lors de l\'ajout de la mécanique aux favoris',
                            text: error.message,
                            type: 'error'
                        });
                    });
            }
        },

        like2(theme) {
            if (this.user.favoriteThemes.find(favoriteTheme => favoriteTheme.id === theme.id)) {
                api.auth.removeFavoriteTheme(theme.id)
                    .then(() => {
                        this.$notify({
                            type: 'success',
                            title: 'Succès',
                            text: `Le thème '${theme.name}' a bien été retiré de vos favoris.`,
                        });
                        this.$store.dispatch('removeFavoriteTheme', theme);
                    })
                    .catch((error) => {
                        this.$notify({
                            title: 'Erreur lors de la suppression du thème des favoris',
                            text: error.message,
                            type: 'error',
                        });
                    });
            } else {
                api.auth.addFavoriteTheme(theme.id)
                    .then(() => {
                        this.$notify({
                            type: 'success',
                            title: 'Succès',
                            text: `Le thème '${theme.name}' a bien été ajouté à vos favoris.`,
                        });
                        this.$store.dispatch('addFavoriteTheme', theme);
                    })
                    .catch((error) => {
                        this.$notify({
                            title: 'Erreur lors de l\'ajout du thème aux favoris',
                            text: error.message,
                            type: 'error',
                        });
                    });
            }
        },
    },

}
</script>

<style lang="scss" scoped>
ul {
    list-style: none;
    padding: 0;
    margin: 0;
}
</style>