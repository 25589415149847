<template>
    <label :for="id">{{ label }}</label>
    <div class="horizontalContainer">
        <div class="input-container">
            <span class="material-symbols-outlined">{{  icon  }}</span>
            <div class="select">
                <select :id="id" required v-model="currentValue" @change="handleChange">
                    <option value="" disabled selected>- {{  placeholder  }} -</option>
                    <option v-for="element of data" :value="element[valueField]" :key="element[valueField]">{{ element[textField] }}
                    </option>
                </select>
                <div class="select_arrow">
                </div>
            </div>
        </div>
        <button class="btn btn-icon" @click="refreshCallback">
            <span class="material-symbols-outlined without-text">refresh</span>
        </button>
        <button class="btn btn-icon" @click="addCallback">
            <span class="material-symbols-outlined without-text">add</span>
        </button>
    </div>
</template>

<script>

export default {
    name: 'LudoteirbSelectInput',
    props: {
        id: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        modelValue: {
            type: String,
            default: ""
        },
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        data: {
            type: Array,
            default: () => []
        },
        valueField: {
            type: String,
            default: 'id'
        },
        textField: {
            type: String,
            default: 'name'
        },
        addCallback: {
            type: Function,
            default: null
        },
        refreshCallback: {
            type: Function,
            default: null
        },
    },
    data() {
        return {
            currentValue: this.modelValue
        }
    },
    methods: {
        handleChange(event) {
            this.$emit('update:modelValue', event.target.value);
        }
    },
    watch: {
        modelValue(value) {
            this.currentValue = value;
        }
    }
}

</script>