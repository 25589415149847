<template>
    <div class="page">
        <header>
            <div class="horizontal-container">
                <h2>Joueurs</h2>
                <div class="horizontal-container">
                    <div class="dropdown">
                        <button class="dropbtn">
                            <span class="material-symbols-outlined">{{ orders.find(f => f.id ===
                                    pagination.orderBy)?.icon
                            }}</span>
                            {{ orders.find(f => f.id === pagination.orderBy)?.name }} ▼
                        </button>
                        <div class="dropdown-content">
                            <a v-for="filter of orders" :key="filter.id" @click="pagination.orderBy = filter.id">
                                <span class="material-symbols-outlined">{{ filter.icon }}</span>
                                {{ filter.name }}
                            </a>
                        </div>
                    </div>
                    <div class="dropdown" style="margin-left: 8px;">
                        <button class="dropbtn">
                            <span class="material-symbols-outlined">format_list_numbered</span>{{ pagination.limit }}
                            résultats par page ▼
                        </button>
                        <div class="dropdown-content">
                            <a v-for="nb in [10, 20, 50, 100]" :key="nb"
                                @click="(pagination = { ...pagination, limit: nb, page: 1 })">
                                {{ nb }} résultats par page
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <pre class="sql-request" v-if="pagination.orderBy == 'reviews'"><code>SELECT Players.*,
(SELECT count(*) FROM Reviews WHERE Reviews.playerId = Players.id) AS quantity
FROM Players
ORDER BY quantity DESC, Players.firstName ASC, Players.lastName ASC
LIMIT {{ pagination.limit }} OFFSET {{ (pagination.page - 1) * pagination.limit }}</code></pre>
            <pre class="sql-request" v-else><code>SELECT Players.*,
(SELECT count(*) FROM ReviewRatings WHERE ReviewRatings.playerId = Players.id) AS quantity
FROM Players
ORDER BY quantity DESC, Players.firstName ASC, Players.lastName ASC
LIMIT {{ pagination.limit }} OFFSET {{ (pagination.page - 1) * pagination.limit }}</code></pre>
        </header>
        <LudoteirbPagination :max-page="(stats.nbPlayers / pagination.limit)" v-model="pagination.page" v-if="stats" />
        <transition-group name="list-complete" tag="div" class="cards" v-if="players">
            <router-link class="card" v-for="player in players" :key="player.id"
                :to="{ name: 'player', params: { id: player.id } }">
                <div class="card-actions" v-if="user">
                    <button class="btn btn-outline btn-icon" @click.prevent="remove(player)" v-if="user.admin">
                        <span class="material-symbols-outlined without-text">delete</span>
                    </button>
                </div>
                <div class="card-image">
                    <img :src="'https://avatars.dicebear.com/api/avataaars/' + player.pseudo + '.svg'"
                        :alt="'Avatar de ' + player.pseudo" />
                </div>
                <div class="card-content">
                    <h3>{{ player.firstName }} {{ player.lastName }}</h3>
                    <h5>@{{ player.pseudo }}</h5>
                </div>
                <div class="card-footer">
                    <div class="card-footer-item">
                        <span class="material-symbols-outlined">{{ pagination.orderBy == 'reviews' ? 'comment' :
                                'thumbs_up_down'
                        }}</span> {{ player.quantity }}
                    </div>
                </div>
            </router-link>
        </transition-group>
        <LudoteirbModal title="Supprimer un joueur" icon="delete" ref="deletePlayerModal"
            :confirm-callback="deletePlayer" confirm-text="Supprimer">
            <p>Êtes-vous sûr de vouloir supprimer le joueur <strong>{{ playerToDelete?.pseudo }}</strong> ?</p>
            <p>Cela supprimera également toutes les critiques qu'il a écrites et les avis qu'il a donnés.</p>
            <code class="sql-request">DELETE FROM Players WHERE id = {{ playerToDelete?.id }};</code>
        </LudoteirbModal>
    </div>
</template>
  
<script>
import api from '@/api';
import { mapState } from 'vuex';
import LudoteirbPagination from '@/components/LudoteirbPagination.vue';
import LudoteirbModal from '@/components/LudoteirbModal.vue';

export default {
    name: "PlayersView",
    computed: {
        ...mapState([
            'user',
            'stats'
        ])
    },
    data() {
        return {
            players: undefined,
            orders: [
                {
                    id: "reviews",
                    name: "Nombre de critiques postées",
                    icon: "star"
                },
                {
                    id: "ratings",
                    name: "Nombre de jugements donnés",
                    icon: "thumb_up"
                }
            ],
            pagination: {
                orderBy: "reviews",
                limit: 20,
                page: 1,
            },
            playerToDelete: undefined,
        };
    },
    watch: {
        pagination: {
            handler() {
                this.fetchPlayers();
            },
            deep: true,
        },
    },
    methods: {
        fetchPlayers() {
            api.players.getOrdered(this.pagination.orderBy, this.pagination.limit, this.pagination.page)
                .then(players => this.players = players.data)
                .catch(err => {
                    console.error(err)
                    this.players = [];
                });
        },
        remove(player) {
            this.playerToDelete = player;
            this.$refs.deletePlayerModal.show();
        },
        deletePlayer() {
            api.players.delete(this.playerToDelete.id)
                .then(() => {
                    // Tout s'est bien passé, on ferme la modale
                    this.$refs.deletePlayerModal.hide();

                    // On affiche un message de succès
                    this.$notify({
                        type: 'success',
                        title: 'Succès',
                        text: `Le joueur '${this.playerToDelete.pseudo}' a bien été supprimé.`
                    });

                    // On recharge la liste des joueurs
                    this.fetchPlayers();
                    this.$store.dispatch('loadStats');
                })
                .catch((error) => {
                    // Une erreur s'est produite, on affiche un message d'erreur
                    this.$notify({
                        title: 'Erreur lors de la suppression',
                        text: error.message,
                        type: 'error'
                    });
                })
        },
    },
    created() {
        this.fetchPlayers();
    },
    components: {
        LudoteirbPagination,
        LudoteirbModal,
    }
}
</script>

<style lang="scss" scoped>
.card {
    text-align: center;
}

.card-footer {
    justify-content: center;
    margin-top: 1rem;
}
</style>