import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import GamesView from '../views/GamesView.vue'
import PlayersView from '../views/PlayersView.vue'
import ThemesView from '../views/ThemesView.vue'
import ThemeView from '../views/ThemeView.vue'
import MechanicsView from '../views/MechanicsView.vue'
import TypesView from '../views/TypesView.vue'
import ReviewsView from '../views/ReviewsView.vue'
import EditorsView from '../views/EditorsView.vue'
import GameView from '../views/GameView.vue'
import PlayerView from '../views/PlayerView.vue'
import ReviewView from '../views/ReviewView.vue'
import UserView from '../views/UserView.vue'

const routes = [{
        path: '/',
        name: 'home',
        component: HomeView
    },
    {
        path: '/games',
        name: 'games',
        component: GamesView
    },
    {
        path: '/games/:id',
        name: 'game',
        component: GameView
    },
    {
        path: '/players',
        name: 'players',
        component: PlayersView
    },
    {
        path: '/players/:id',
        name: 'player',
        component: PlayerView
    },
    {
        path: '/themes',
        name: 'themes',
        component: ThemesView
    },
    {
        path: '/themes/:id',
        name: 'theme',
        component: ThemeView
    },
    {
        path: '/mechanics',
        name: 'mechanics',
        component: MechanicsView
    },
    {
        path: '/types',
        name: 'types',
        component: TypesView
    },
    {
        path: '/reviews',
        name: 'reviews',
        component: ReviewsView
    },
    {
        path: '/reviews/:id',
        name: 'review',
        component: ReviewView
    },
    {
        path: '/editors',
        name: 'editors',
        component: EditorsView
    },
    {
        path: '/user',
        name: 'user',
        component: UserView
    },
    {
        path: '/about',
        name: 'about',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/AboutView.vue')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
})

export default router