<template>
    <div class="pagination">
        <button v-if="currentValue > 1" @click="setPage(currentValue - 1)">
            <span class="material-symbols-outlined without-text">chevron_left</span>
        </button>
        <button v-if="currentValue > 2" @click="setPage(currentValue - 2)">
            {{ currentValue - 2 }}
        </button>
        <button v-if="currentValue > 1" @click="setPage(currentValue - 1)">
            {{ currentValue - 1 }}
        </button>
        <button class="active">
            {{ currentValue }}
        </button>
        <button v-if="currentValue < maxPage" @click="setPage(currentValue + 1)">
            {{ currentValue + 1 }}
        </button>
        <button v-if="currentValue < (maxPage - 1)" @click="setPage(currentValue + 2)">
            {{ currentValue + 2 }}
        </button>
        <button v-if="currentValue < maxPage" @click="setPage(currentValue + 1)">
            <span class="material-symbols-outlined without-text">chevron_right</span>
        </button>
    </div>
</template>

<script>

export default {
    name: 'LudoteirbPagination',
    model: {
        prop: 'modelValue',
        event: 'change'
    },
    props: {
        modelValue: {
            required: true,
            type: Number,
        },
        maxPage: {
            required: true,
            type: Number,
        },
    },
    data() {
        return {
            currentValue: this.modelValue
        }
    },
    methods: {
        setPage(page) {
            this.currentValue = page;
            this.$emit('update:modelValue', this.currentValue);
        }
    },
    watch: {
        modelValue(value) {
            this.currentValue = value;
        }
    }

}

</script>