<template>
    <label :for="id">{{label}}</label>
    <div class="horizontalContainer">
        <div class="input-container">
            <span class="material-symbols-outlined">{{ icon }}</span>
            <span class="btn-value">{{ currentValue }} {{ text }}</span>
        </div>
        <button class="btn btn-icon" @click="decrement(1)" :disabled="currentValue <= min"
            @contextmenu.prevent="decrement(10)">
            <span class="material-symbols-outlined without-text">remove</span>
        </button>
        <button class="btn btn-icon" @click="increment(1)" :disabled="currentValue >= max"
            @contextmenu.prevent="increment(10)">
            <span class="material-symbols-outlined without-text">add</span>
        </button>
    </div>
</template>

<script>

export default {
    name: 'LudoteirbNumberInput',
    model: {
        prop: 'modelValue',
        event: 'change'
    },
    props: {
        id: {
            type: String,
            required: true
        },
        icon: {
            type: String,
            required: true
        },
        modelValue: {
            type: Number,
            default: 0
        },
        label: {
            type: String,
            default: ''
        },
        text: {
            type: String,
            default: ''
        },
        max: {
            type: Number,
            default: 99
        },
        min: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            currentValue: this.modelValue
        }
    },
    methods: {
        increment(count = 1) {
            this.currentValue += count;
            if (this.currentValue > this.max) this.currentValue = this.max;
            this.$emit('update:modelValue', this.currentValue);
        },
        decrement(count = 1) {
            this.currentValue -= count;
            if (this.currentValue < this.min) this.currentValue = this.min;
            this.$emit('update:modelValue', this.currentValue);
        }
    }

}

</script>