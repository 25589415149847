<template>
    <!-- The Modal -->
    <div class="modal" :class="{ shown: shown }" @click.self="shown = false">

        <!-- Modal content -->
        <div class="modal-content">
            <div class="modal-header">
                <span class="modal-title">
                    <span class="material-symbols-outlined without-text">{{ icon }}</span>
                    {{ title }}
                </span>
                <span class="modal-close" @click="shown = false">
                    <span class="material-symbols-outlined without-text">close</span>
                </span>
            </div>
            <div class="modal-body">
                <slot></slot>
            </div>
            <div class="modal-actions">
                <button class="btn btn-outline" @click="cancel()">{{ cancelText }}</button>
                <button class="btn" @click="confirmCallback()">{{ confirmText }}</button>
            </div>
        </div>

    </div>
</template>

<script>

export default {
    name: 'LudoteirbModal',
    props: {
        title: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        cancelText: {
            type: String,
            default: 'Annuler'
        },
        cancelCallback: {
            type: Function,
            default: null
        },
        confirmText: {
            type: String,
            default: 'Enregistrer'
        },
        confirmCallback: {
            type: Function,
            default: null
        },
    },
    data() {
        return {
            shown: false
        }
    },
    methods: {
        show() {
            this.shown = true;
            const firstInput = this.$el.nextElementSibling.querySelector('[autofocus]')
            if (firstInput) {
                setTimeout(() => {
                    firstInput.focus();
                }, 100);
            }
        },
        hide() {
            this.shown = false;
        },
        cancel() {
            this.hide();
            if (this.cancelCallback) {
                this.cancelCallback();
            }
        }
    }
}
</script>