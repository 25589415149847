<template>
    <div class="page">
        <header>
            <h2>Thèmes</h2>
        </header>
        <pre class="sql-request"><code>SELECT * FROM Themes ORDER BY name ASC;</code></pre>
        <div class="cards" v-if="themes">
            <router-link class="card" v-for="theme in themes" :key="theme.id"
                :style="{ borderTop: '8px solid ' + colors[theme.id % colors.length] }"
                :to="{ name: 'theme', params: { id: theme.id } }">
                <div class="card-actions" v-if="user">
                    <button class="btn btn-outline btn-icon" @click.prevent="remove(theme)" v-if="user.admin">
                        <span class="material-symbols-outlined without-text">delete</span>
                    </button>
                    <button class="btn btn-icon" @click.prevent="like(theme)"
                        v-if="user.favoriteThemes.find(favoriteTheme => favoriteTheme.id === theme.id)">
                        <span class="material-symbols-outlined without-text">favorite</span>
                    </button>
                    <button class="btn btn-outline btn-icon" @click.prevent="like(theme)" v-else>
                        <span class="material-symbols-outlined without-text">heart_plus</span>
                    </button>
                </div>
                <div class="card-content">
                    <h3>{{ theme.name }}</h3>
                    <p>{{ theme.description }}</p>
                </div>
            </router-link>
        </div>
        <LudoteirbModal title="Ajouter un thème" icon="add" ref="addThemeModal" :cancel-callback="clearForm"
            :confirm-callback="createTheme">
            <div class="form">
                <LudoteirbTextInput id="themeName" icon="label" label="Nom du thème" placeholder="Entrez un nom"
                    v-model="newTheme.name" />
                <LudoteirbTextInput id="themeDescription" icon="description" label="Description du thème"
                    placeholder="Entrez une description" multiline v-model="newTheme.description" />
            </div>
            <code class="sql-request">
                INSERT INTO Themes (name, description) VALUES ('{{ newTheme.name.replace("'", "''") }}', '{{ newTheme.description.replace("'", "''") }}');
            </code>
        </LudoteirbModal>
        <LudoteirbModal title="Supprimer un thème" icon="delete" ref="deleteThemeModal" :confirm-callback="deleteTheme"
            confirm-text="Supprimer">
            <p>Êtes-vous sûr de vouloir supprimer le thème <strong>{{ themeToDelete?.name }}</strong>&nbsp;?</p>
            <code class="sql-request">DELETE FROM Themes WHERE id = {{ themeToDelete?.id }};</code>
        </LudoteirbModal>
        <button class="fab" @click="add()" v-if="user?.admin">
            <span class="material-symbols-outlined without-text">add</span>
        </button>
    </div>
</template>

<script>
import api from '@/api';
import LudoteirbModal from '@/components/LudoteirbModal';
import LudoteirbTextInput from '@/components/LudoteirbTextInput';
import { mapState } from 'vuex';

const NEW_THEME = {
    name: '',
    description: '',
};

export default {
    name: 'ThemesView',
    computed: {
        ...mapState([
            'colors',
            'user',
            'themes',
        ])
    },
    data() {
        return {
            newTheme: { ...NEW_THEME },
            themeToDelete: null,
        };
    },
    methods: {
        clearForm() {
            this.newTheme = { ...NEW_THEME };
        },
        add() {
            this.$refs.addThemeModal.show();
        },
        createTheme() {
            api.themes.create(this.newTheme)
                .then(() => {
                    // Tout s'est bien passé, on ferme la modale
                    this.$refs.addThemeModal.hide();

                    // On affiche un message de succès
                    this.$notify({
                        type: 'success',
                        title: 'Succès',
                        text: `Le thème '${this.newTheme.name}' a bien été créé.`,
                    });

                    // On réinitialise les données du formulaire
                    this.clearForm();

                    // On recharge la liste des thèmes
                    this.$store.dispatch('loadThemes');
                })
                .catch((error) => {
                    // Une erreur s'est produite, on affiche un message d'erreur
                    this.$notify({
                        title: 'Erreur lors de la création',
                        text: error.message,
                        type: 'error',
                    });
                });
        },
        remove(theme) {
            this.themeToDelete = theme;
            this.$refs.deleteThemeModal.show();
        },
        deleteTheme() {
            api.themes.delete(this.themeToDelete.id)
                .then(() => {
                    // Tout s'est bien passé, on ferme la modale
                    this.$refs.deleteThemeModal.hide();

                    // On affiche un message de succès
                    this.$notify({
                        type: 'success',
                        title: 'Succès',
                        text: `Le thème '${this.themeToDelete.name}' a bien été supprimé.`,
                    });

                    // On recharge la liste des thèmes
                    this.$store.dispatch('loadThemes');
                })
                .catch((error) => {
                    // Une erreur s'est produite, on affiche un message d'erreur
                    this.$notify({
                        title: 'Erreur lors de la suppression',
                        text: error.message,
                        type: 'error',
                    });
                });
        },
        like(theme) {
            if (this.user.favoriteThemes.find(favoriteTheme => favoriteTheme.id === theme.id)) {
                api.auth.removeFavoriteTheme(theme.id)
                    .then(() => {
                        this.$notify({
                            type: 'success',
                            title: 'Succès',
                            text: `Le thème '${theme.name}' a bien été retiré de vos favoris.`,
                        });
                        this.$store.dispatch('removeFavoriteTheme', theme);
                    })
                    .catch((error) => {
                        this.$notify({
                            title: 'Erreur lors de la suppression du thème des favoris',
                            text: error.message,
                            type: 'error',
                        });
                    });
            } else {
                api.auth.addFavoriteTheme(theme.id)
                    .then(() => {
                        this.$notify({
                            type: 'success',
                            title: 'Succès',
                            text: `Le thème '${theme.name}' a bien été ajouté à vos favoris.`,
                        });
                        this.$store.dispatch('addFavoriteTheme', theme);
                    })
                    .catch((error) => {
                        this.$notify({
                            title: 'Erreur lors de l\'ajout du thème aux favoris',
                            text: error.message,
                            type: 'error',
                        });
                    });
            }
        },
    },
    components: {
        LudoteirbModal,
        LudoteirbTextInput,
    },
}

</script>