<template>
  <notifications position="bottom right" />
  <div class="sidenav" :class="{ 'open': menuOpen }">
    <div class="closeBtn" @click="menuOpen = false">
      <span class="material-symbols-outlined without-text">close</span>
    </div>
    <h1>
      <a href="#">
        <img src="/img/logo.svg" alt="logo" />
        <span class="title">Ludot'eirb</span>
      </a>
    </h1>
    <router-link to="/"><span class="material-symbols-outlined">home</span> Accueil</router-link>
    <router-link to="/games"><span class="material-symbols-outlined">casino</span> Jeux <span class="badge"
        style="--color: #c0392b">{{ stats?.nbGames }}</span></router-link>
    <router-link to="/players"><span class="material-symbols-outlined">group</span> Joueurs <span class="badge"
        style="--color: #2ecc71">{{ stats?.nbPlayers }}</span></router-link>
    <router-link to="/themes"><span class="material-symbols-outlined">palette</span> Thèmes <span class="badge"
        style="--color: #f1c40f">{{ themes?.length }}</span></router-link>
    <router-link to="/mechanics"><span class="material-symbols-outlined">build</span> Mécaniques <span class="badge"
        style="--color: #3498db">{{ mechanics?.length }}</span></router-link>
    <router-link to="/types"><span class="material-symbols-outlined">category</span> Types <span class="badge"
        style="--color: #e67e22">{{ types?.length }}</span></router-link>
    <router-link to="/reviews"><span class="material-symbols-outlined">star</span> Critiques <span class="badge"
        style="--color: #9b59b6">{{ stats?.nbReviews }}</span></router-link>
    <router-link to="/editors"><span class="material-symbols-outlined">business</span> Éditeurs <span class="badge"
        style="--color: #1abc9c">{{ editors?.length }}</span></router-link>
    <a><span class="material-symbols-outlined">code</span> Afficher SQL
      <LudoteirbSwitch id="showSqlRequests" v-model="showSqlRequests" round small
        style="float: right; margin-top: -2px" />
    </a>
  </div>

  <ul class="topnav">
    <li class="menuBtn">
      <a @click="menuOpen = !menuOpen">
        <span class="material-symbols-outlined without-text">menu</span>
      </a>
    </li>
    <li class="nav-dropdown" style="float: right" v-if="user">
      <a href="javascript:void(0)" class="nav-dropbtn">
        <img :src="'https://avatars.dicebear.com/api/avataaars/' + user.pseudo + '.svg'" alt="avatar" class="avatar" />
        <span class="username">{{ user.firstName }} {{ user.lastName }} ▼</span>
      </a>
      <div class="nav-dropdown-content">
        <router-link to="/user">
          <span class="material-symbols-outlined">person</span>
          Mon profil
        </router-link>
        <a @click="logout()">
          <span class="material-symbols-outlined">logout</span>
          Déconnexion
        </a>
      </div>
    </li>
    <li style="float: right" v-else>
      <a @click="loginModal()">
        <span class="material-symbols-outlined">login</span>
        Connexion
      </a>
    </li>
    <li style="float: right" v-if="!user">
      <a @click="registerModal()">
        <span class="material-symbols-outlined">person_add</span>
        Inscription
      </a>
    </li>
  </ul>
  <div class="content" :class="{ 'show-sql-requests': showSqlRequests }">
    <LudoteirbModal title="Connexion" icon="lock" ref="authModal" confirmText="Se connecter" :confirmCallback="login">
      <div class="form">
        <LudoteirbTextInput id="pseudo" icon="person" label="Identifiant" placeholder="Adresse email ou pseudo"
          v-model="pseudo" autofocus />
        <LudoteirbTextInput id="password" icon="lock" label="Mot de passe" placeholder="****************"
          type="password" v-model="password" @keydown.enter="login" />
      </div>
      <code
        class="sql-request">SELECT * FROM Players WHERE Players.pseudo = '{{ pseudo.replace("'", "''") }}' OR Players.email = '{{ pseudo.replace("'", "''") }}';</code>
    </LudoteirbModal>
    <LudoteirbModal title="Inscription" icon="person_add" ref="registerModal" confirmText="S'inscrire"
      :confirmCallback="register">
      <div class="form">
        <LudoteirbTextInput id="pseudo" icon="person" label="Identifiant" placeholder="Entrez votre pseudo" v-model="registerData.pseudo"
          autofocus />
        <LudoteirbTextInput id="email" icon="email" label="Adresse email" placeholder="Entrez votre adresse email"
          v-model="registerData.email" />
        <LudoteirbTextInput id="firstName" icon="person" label="Prénom" placeholder="Entrez votre prénom"
          v-model="registerData.firstName" />
        <LudoteirbTextInput id="lastName" icon="person" label="Nom" placeholder="Entrez votre nom" v-model="registerData.lastName" />
        <LudoteirbTextInput id="password" icon="lock" label="Mot de passe"
          placeholder="Toujours 'password' pour la démo" disabled />
      </div>
      <code
        class="sql-request">INSERT INTO Players (pseudo, email, admin, firstName, lastNameà VALUES ('{{ registerData.pseudo.replace("'", "''") }}', '{{ registerData.email.replace("'", "''") }}', 0, '{{ registerData.firstName.replace("'", "''") }}', '{{ registerData.lastName.replace("'", "''") }}');</code>
    </LudoteirbModal>
    <router-view v-slot="{ Component }">
      <transition name="fade" mode="out-in">
        <component :is="Component" />
      </transition>
    </router-view>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import api from './api';
import LudoteirbModal from './components/LudoteirbModal.vue';
import LudoteirbTextInput from './components/LudoteirbTextInput.vue';
import LudoteirbSwitch from './components/LudoteirbSwitch.vue';

const NEW_USER = {
  pseudo: '',
  email: '',
  firstName: '',
  lastName: '',
};

export default {
  name: 'App',
  computed: mapState([
    'user',
    'editors',
    'types',
    'mechanics',
    'themes',
    'stats',
  ]),
  watch: {
    '$route': function () {
      this.menuOpen = false;
    }
  },
  data() {
    return {
      menuOpen: false,
      pseudo: '',
      password: '',
      showSqlRequests: true,
      registerData: { ...NEW_USER },
    }
  },
  async created() {
    try {
      const user = await api.auth.user();
      this.$store.commit("setUser", user.data);
    } catch (error) {
      if (error.code === "AUTH/USER_NOT_LOGGED_IN") {
        this.$store.commit("setUser", null);
      }
    }
    this.$store.dispatch('loadBaseData');
  },
  methods: {
    clearForm() {
      this.registerData = { ...NEW_USER };
    },
    loginModal() {
      this.$refs.authModal.show();
    },
    registerModal() {
      this.$refs.registerModal.show();
    },
    async login() {
      api.auth.login(this.pseudo, this.password)
        .then((user) => {
          this.$store.commit("setUser", user.data);
          this.$refs.authModal.hide();
          this.pseudo = '';
          this.password = '';
          this.$notify({
            type: "success",
            title: "Connexion réussie",
            text: `Bienvenue ${user.data.firstName} ${user.data.lastName} !`,
          })
        })
        .catch((error) => {
          this.$notify({
            title: "Erreur de connexion",
            text: error.message,
            type: "error",
          });
          this.pseudo = '';
          this.password = '';
        });
    },
    async register() {
      api.auth.register(this.registerData.pseudo, this.registerData.email, this.registerData.firstName, this.registerData.lastName)
        .then((user) => {
          this.$store.commit("setUser", user.data);
          this.$refs.registerModal.hide();
          this.clearForm();
          this.$notify({
            type: "success",
            title: "Inscription réussie",
            text: `Bienvenue ${user.data.firstName} ${user.data.lastName} !`,
          })
        })
        .catch((error) => {
          this.$notify({
            title: "Erreur d'inscription",
            text: error.message,
            type: "error",
          });
        });
    },
    logout() {
      api.auth.logout()
        .then(() => {
          if (this.$route.name !== 'home') {
            this.$router.push({ name: 'home' });
          }
          this.$store.commit("setUser", null);
          this.$notify({
            text: "Déconnexion réussie !",
            type: "success"
          })
        });
    }
  },
  components: {
    LudoteirbModal,
    LudoteirbTextInput,
    LudoteirbSwitch,
  }
}
</script>


<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
