import { createStore } from 'vuex'
import { notify } from '@kyvg/vue3-notification'

import api from '@/api'

export default createStore({
  state: {
    colors: [
      "#1abc9c",
      "#2ecc71",
      "#3498db",
      "#9b59b6",
      "#34495e",
      "#16a085",
      "#27ae60",
      "#2980b9",
      "#8e44ad",
      "#2c3e50",
      "#f1c40f",
      "#e67e22",
      "#e74c3c",
      "#f39c12",
      "#d35400",
      "#c0392b",
    ],
    user: undefined,
    stats: undefined,
    editors: undefined,
    types: undefined,
    mechanics: undefined,
    themes: undefined,
    people: undefined,
    partialGames: undefined,
  },
  getters: {
  },
  mutations: {
    setUser(state, user) {
      state.user = user
    },
  },
  actions: {
    async loadBaseData({ dispatch }) {
      try {
        await dispatch("loadStats");
        await dispatch("loadEditors");
        await dispatch("loadTypes");
        await dispatch("loadMechanics");
        await dispatch("loadThemes");
        await dispatch("loadPeople");
        await dispatch("loadPartialGames");
      } catch (error) {
        console.error(error);
        notify({
          text: "Erreur lors de la récupération des informations de base",
          type: "error",
        });
      }
    },
    async loadStats({ state }) {
      state.stats = (await api.stats()).data;
    },
    async loadEditors({ state }) {
      state.editors = (await api.editors.list()).data;
    },
    async loadTypes({ state }) {
      state.types = (await api.types.list()).data;
    },
    async loadMechanics({ state }) {
      state.mechanics = (await api.mechanics.list()).data;
    },
    async loadThemes({ state }) {
      state.themes = (await api.themes.list()).data;
    },
    async loadPeople({ state }) {
      state.people = (await api.people.list()).data;
    },
    async loadPartialGames({ state }) {
      state.partialGames = (await api.games.partialList()).data;
    },
    async loadUser({ state }) {
      state.user = (await api.auth.user()).data;
    },
    addFavoriteTheme({ state, commit }, theme) {
      commit("setUser", {
        ...state.user,
        favoriteThemes: [...state.user.favoriteThemes, theme],
      });
    },
    removeFavoriteTheme({ state, commit }, theme) {
      commit("setUser", {
        ...state.user,
        favoriteThemes: state.user.favoriteThemes.filter(
          (t) => t.id !== theme.id
        ),
      });
    },
    addFavoriteMechanic({ state, commit }, mechanic) {
      commit("setUser", {
        ...state.user,
        favoriteMechanics: [...state.user.favoriteMechanics, mechanic],
      });
    },
    removeFavoriteMechanic({ state, commit }, mechanic) {
      commit("setUser", {
        ...state.user,
        favoriteMechanics: state.user.favoriteMechanics.filter(
          (m) => m.id !== mechanic.id
        ),
      });
    },
  },
  modules: {
  }
})