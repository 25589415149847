<template>
  <div class="page">
    <header>
      <h2>Détails de la critique :</h2>
    </header>
    <!-- <code>
            <pre>{{ review }}</pre>
        </code>
        <code>
            <pre>{{ likes }}</pre>
        </code> -->
    <div v-if="review">
      <div>
        <img :src="review.picture">
      </div>
      <h2><router-link :to="`/games/${review.gameId}`">{{ review.name }}</router-link></h2>
      <h3 v-if="review.parentGame != null">Ce jeu est une extension de <router-link
          :to="`/games/${review.parentGameId}`">{{ review.parentGame }}</router-link></h3>
      <h3 v-else>Ce jeu n'est pas une extension</h3>
      <div v-if="review.extensions.length > 0">
        <h3>Cette critique porte sur ce jeu et les entensions suivantes :</h3>
        <ul v-if="partialGames">
          <li v-for="extensionId in review.extensions" :key="extensionId">
            <router-link :to="{ 'name': 'game', 'params': { 'id': extensionId } }">
              {{ partialGames.find(game => game.id == extensionId).name }}
            </router-link>
          </li>
        </ul>
      </div>

      <h2>Note : {{ review.value }}/20</h2>
      <h2>Nombre de joueurs : {{ review.playersCount }}</h2>
      <div class="comment card card-comment-details white z-depth-0" data-id="review.id" data-name="review.name"
        data-text="review.content">
        <div class="card-content">
          <div class="comment-container">
            <div class="comment-avatar">
              <img :src="('https://avatars.dicebear.com/api/avataaars/' + review.pseudo + '.svg')" width="128"
                :alt="'Photo de profil du joueur ' + review.pseudo">
            </div>
            <div class="comment-header">

              <h4>{{ review.pseudo }} a dit le {{ review.publicationDate }} :</h4>

            </div>
            <div class="comment-body">
              {{ review.comment }}
            </div>
          </div>
        </div>
      </div>

      <h2>Personnes ayant aimés ce commentaire :</h2>
      <li v-for="player in likes" :key="player.id">
        <div class="comment card card-comment-details white z-depth-0" data-id="player.id" data-name="player.name"
          data-text="review.content">
          <div>
            <router-link :to="`/players/${player.id}`">
              <div class="comment-container" style="display : flex;">
                <div class="comment-avatar">
                  <img :src="('https://avatars.dicebear.com/api/avataaars/' + player.pseudo + '.svg')" width="64"
                    :alt="'Photo de profil du joueur ' + player.pseudo">
                </div>
                <div class="comment-header">

                  <h4> {{ player.pseudo }}</h4>

                </div>
              </div>
            </router-link>
          </div>
        </div>
      </li>
    </div>
  </div>
</template>
  
<script>
import api from '@/api';
import { mapState } from 'vuex';

export default {
  name: 'ReviewView',
  computed: {
    ...mapState([
      'partialGames',
    ])
  },
  data() {
    return {
      review: undefined,
      likes: undefined
    }
  },
  methods: {
  },
  created() {
    api.reviews.getWithPlayerAndGame(this.$route.params.id)
      .then(review => this.review = review.data)
      .catch(err => console.error(err)),
      api.reviews.getLikes(this.$route.params.id)
        .then(likes => this.likes = likes.data)
        .catch(err => console.error(err))
  }
}
</script>

<style scoped>

a {
  color: black;
  text-decoration: none;
}
</style>