<template>
    <div class="page">
        <header>
            <h2 v-if="player"><router-link :to="{ name: 'players' }">Joueurs</router-link> <span
                    class="material-symbols-outlined without-text">double_arrow</span> {{ player.firstName }} {{ player.lastName }}</h2>
        </header>
        <code>        
                <pre>{{ player }}</pre>
        </code>
            <div class="cards" v-if="player">
            <div class="card-image">
                        <img :src="'https://avatars.dicebear.com/api/avataaars/' + player.pseudo + '.svg'"
                            :alt="'Avatar de ' + player.pseudo" />
            </div>
            <div class="card-content">
                        <h3>{{ player.firstName }} {{ player.lastName }}</h3>
                        <h5>@{{ player.pseudo }}</h5>
            </div>
        </div>
    </div>
</template>
  
<script>
import api from '@/api'

export default {
    name: 'PlayerView',
    data() {
        return {
            player: undefined
        }
    },
    methods: {
    },
    created() {
        api.players.get(this.$route.params.id)
            .then(player => this.player = player.data)
            .catch(err => console.error(err))
    }
}
</script>
  