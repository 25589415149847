<template>
    <div class="page">
        <header>
            <h2>Types de jeu</h2>
        </header>
        <pre class="sql-request"><code>SELECT * FROM Types ORDER BY name ASC;</code></pre>
        <div class="cards" v-if="types">
            <router-link class="card" v-for="gameType in types" :key="gameType.id"
                :style="{ backgroundColor: colors[gameType.id % colors.length] }"
                :to="{ name: 'games', query: { type: gameType.id } }">
                <div class="card-actions-absolute" v-if="user?.admin">
                    <button class="btn btn-outline btn-icon" @click.prevent="remove(gameType)">
                        <span class="material-symbols-outlined without-text">delete</span>
                    </button>
                </div>
                <div class="card-icon">
                    <span class="material-symbols-outlined">{{ gameType.icon }}</span>
                </div>
                <div class="card-content card-icon-content">
                    <h3>{{ gameType.name }}</h3>
                </div>
            </router-link>
        </div>
        <LudoteirbModal title="Ajouter un type de jeu" icon="add" ref="addTypeModal" :cancel-callback="clearForm"
            :confirm-callback="createType">
            <div class="form">
                <LudoteirbTextInput id="typeName" icon="label" label="Nom du type de jeu" placeholder="Entrez un nom"
                    v-model="newType.name" />
                <LudoteirbTextInput id="typeIcon" icon="emoji_symbols" label="Icône du type de jeu"
                    placeholder="Entrez un nom d'icône" v-model="newType.icon" />
            </div>
            <code class="sql-request">
                INSERT INTO Types (name, icon) VALUES ('{{ newType.name.replace("'", "''") }}', '{{ newType.icon.replace("'", "''") }}');
            </code>
        </LudoteirbModal>
        <LudoteirbModal title="Supprimer un type de jeu" icon="delete" ref="deleteTypeModal"
            :confirm-callback="deleteType" confirm-text="Supprimer">
            <p>Êtes-vous sûr de vouloir supprimer le type de jeu <strong>{{ typeToDelete?.name }}</strong>&nbsp;?</p>
            <p>Cela supprimera également les jeux de ce type, et par conséquent les données associées telles que les
                commentaires.</p>
            <code class="sql-request">DELETE FROM Types WHERE id = {{ typeToDelete?.id }};</code>
        </LudoteirbModal>
        <button class="fab" @click="add()" v-if="user?.admin">
            <span class="material-symbols-outlined without-text">add</span>
        </button>
    </div>
</template>
  
<script>
import api from '@/api';
import LudoteirbModal from '@/components/LudoteirbModal';
import LudoteirbTextInput from '@/components/LudoteirbTextInput';
import { mapState } from 'vuex'

const NEW_TYPE = {
    name: '',
    icon: ''
};

export default {
    name: 'TypesView',
    computed: {
        ...mapState([
            'colors',
            'user',
            'types',
        ])
    },
    data() {
        return {
            newType: { ...NEW_TYPE },
            typeToDelete: null,
        };
    },
    methods: {
        clearForm() {
            this.newType = { ...NEW_TYPE };
        },
        add() {
            this.$refs.addTypeModal.show();
        },
        createType() {
            api.types.create(this.newType)
                .then(() => {
                    // Tout s'est bien passé, on ferme la modale
                    this.$refs.addTypeModal.hide();

                    // On affiche un message de succès
                    this.$notify({
                        type: 'success',
                        title: 'Succès',
                        text: `Le type de jeu '${this.newType.name}' a bien été créé.`,
                    });

                    // On réinitialise les données du formulaire
                    this.clearForm();

                    // On recharge la liste des types de jeu, des jeux et des commentaires
                    this.$store.dispatch('loadTypes');
                    this.$store.dispatch('loadGames');
                    this.$store.dispatch('loadReviews');
                })
                .catch((error) => {
                    // Une erreur s'est produite, on affiche un message d'erreur
                    this.$notify({
                        title: 'Erreur lors de la création',
                        text: error.message,
                        type: 'error',
                    });
                });
        },
        remove(type) {
            this.typeToDelete = type;
            this.$refs.deleteTypeModal.show();
        },
        deleteType() {
            api.types.delete(this.typeToDelete.id)
                .then(() => {
                    // Tout s'est bien passé, on ferme la modale
                    this.$refs.deleteTypeModal.hide();

                    // On affiche un message de succès
                    this.$notify({
                        type: 'success',
                        title: 'Succès',
                        text: `Le type de jeu '${this.typeToDelete.name}'' a bien été supprimé.`,
                    });

                    // On recharge la liste des types de jeu
                    this.$store.dispatch('loadTypes');
                })
                .catch((error) => {
                    // Une erreur s'est produite, on affiche un message d'erreur
                    this.$notify({
                        title: 'Erreur lors de la suppression',
                        text: error.message,
                        type: 'error',
                    });
                });
        },
    },
    components: {
        LudoteirbModal,
        LudoteirbTextInput,
    },
}
</script>
  
<style lang="scss" scoped>
.card-actions-absolute {
    position: absolute;
    top: 5px;
    right: 5px;
}
</style>