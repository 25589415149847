<template>
    <div class="page">
        <header>
            <h2>Éditeurs</h2>
        </header>
        <pre class="sql-request"><code>SELECT * FROM Editors ORDER BY name ASC;</code></pre>
        <div class="cards" v-if="editors">
            <div class="card" v-for="editor in editors" :key="editor.id"
                :style="{ borderTop: '8px solid ' + colors[editor.id % colors.length] }">
                <div class="card-actions" v-if="user?.admin">
                    <button class="btn btn-outline btn-icon" @click="remove(editor)">
                        <span class="material-symbols-outlined without-text">delete</span>
                    </button>
                </div>
                <div class="card-content">
                    <h3>{{ editor.name }}</h3>
                </div>
            </div>
        </div>
        <LudoteirbModal title="Ajouter un éditeur" icon="add" ref="addEditorModal" :cancel-callback="clearForm"
            :confirm-callback="createEditor">
            <div class="form">
                <LudoteirbTextInput id="editorName" icon="label" label="Nom de l'éditeur" placeholder="Entrez un nom"
                    v-model="newEditor.name" />
            </div>
            <code class="sql-request">
                INSERT INTO Editors (name) VALUES ('{{ newEditor.name.replace("'", "''") }}');
            </code>
        </LudoteirbModal>
        <LudoteirbModal title="Supprimer un éditeur" icon="delete" ref="deleteEditorModal"
            :confirm-callback="deleteEditor" confirm-text="Supprimer">
            <p>Êtes-vous sûr de vouloir supprimer l'éditeur <strong>{{ editorToDelete?.name }}</strong>&nbsp;?</p>
            <p>Cela supprimera également tous les jeux édités par celui-ci, et les données associées telles que les
                commentaires.</p>
            <code class="sql-request">DELETE FROM Editors WHERE id = {{ editorToDelete?.id }};</code>
        </LudoteirbModal>
        <button class="fab" @click="add()" v-if="user?.admin">
            <span class="material-symbols-outlined without-text">add</span>
        </button>
    </div>
</template>
  
<script>
import api from '@/api';
import LudoteirbModal from '@/components/LudoteirbModal.vue';
import LudoteirbTextInput from '@/components/LudoteirbTextInput.vue';
import { mapState } from 'vuex';

const NEW_EDITOR = {
    name: ''
};

export default {
    name: 'EditorsView',
    computed: {
        ...mapState([
            'colors',
            'user',
            'editors',
        ])
    },
    data() {
        return {
            newEditor: { ...NEW_EDITOR },
            editorToDelete: null,
        }
    },
    methods: {
        clearForm() {
            this.newEditor = { ...NEW_EDITOR };
        },
        add() {
            this.$refs.addEditorModal.show()
        },
        createEditor() {
            api.editors.create(this.newEditor)
                .then(() => {
                    // Tout s'est bien passé, on ferme la modale
                    this.$refs.addEditorModal.hide();

                    // On affiche un message de succès
                    this.$notify({
                        type: 'success',
                        title: 'Succès',
                        text: `L'éditeur '${this.newEditor.name}' a bien été créé.`
                    });

                    // On réinitialise les données du formulaire
                    this.clearForm();

                    // On recharge la liste des éditeurs
                    this.$store.dispatch('loadEditors');
                })
                .catch((error) => {
                    // Une erreur s'est produite, on affiche un message d'erreur
                    this.$notify({
                        title: 'Erreur lors de la création',
                        text: error.message,
                        type: 'error'
                    });
                });
        },
        remove(editor) {
            this.editorToDelete = editor;
            this.$refs.deleteEditorModal.show();
        },
        deleteEditor() {
            api.editors.delete(this.editorToDelete.id)
                .then(() => {
                    // Tout s'est bien passé, on ferme la modale
                    this.$refs.deleteEditorModal.hide();

                    // On affiche un message de succès
                    this.$notify({
                        type: 'success',
                        title: 'Succès',
                        text: `L'éditeur '${this.editorToDelete.name}' a bien été supprimé.`
                    });

                    // On recharge la liste des éditeurs, des jeux et des commentaires
                    this.$store.dispatch('loadEditors');
                    this.$store.dispatch('loadGames');
                    this.$store.dispatch('loadReviews');
                })
                .catch((error) => {
                    // Une erreur s'est produite, on affiche un message d'erreur
                    this.$notify({
                        title: 'Erreur lors de la suppression',
                        text: error.message,
                        type: 'error'
                    });
                });
        }
    },
    components: {
        LudoteirbModal,
        LudoteirbTextInput
    }
}
</script>