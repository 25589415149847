<template>
  <div class="page">
    <header>
      <h2 v-if="game"><router-link :to="{ name: 'games' }">Jeux</router-link> <span
          class="material-symbols-outlined without-text">double_arrow</span> {{ game.name }}</h2>
    </header>

    <div v-if="game">
      <img :src="game.picture" alt="Image du jeu" class="game-picture">
      <p>{{ game.description }}</p>
      <!-- General info -->
      <div>
        <h3>Informations générales :</h3>
        <ul>
          <li v-if="editors">
            <span class="material-symbols-outlined">business</span>
            Éditeur : {{ editors.find(editor => editor.id === game.editorId).name }}
          </li>
          <li>
            <span class="material-symbols-outlined">event</span>
            Date de sortie : {{ dateString(game.releaseDate) }}
          </li>
          <li v-if="types">
            <span class="material-symbols-outlined">category</span>
            Type de jeu : {{ types.find(type => type.id === game.typeId).name }}
          </li>
          <li>
            <span class="material-symbols-outlined">group</span>
            Nombre de joueurs : {{ game.minPlayers }}{{ game.maxPlayers != game.minPlayers ? '-' + game.maxPlayers :
                ''
            }}
          </li>
          <li>
            <span class="material-symbols-outlined">schedule</span>
            Durée de partie : {{ game.duration }} minute{{ game.duration > 1 ? 's' : '' }}
          </li>
          <li v-if="(game.reviews.length > 0)">
            <span class="material-symbols-outlined">star</span>
            Note moyenne : {{ parseFloat(game.reviews.reduce((acc, review) => acc + review.value, 0) /
                game.reviews.length).toFixed(2)
            }}/20
          </li>
        </ul>
      </div>
      <!-- Expansion -->
      <div v-if="game.parentGameId">
        <h3>Extension de :</h3>
        <ul>
          <li v-if="partialGames"><router-link :to="{ name: 'game', params: { id: game.parentGameId } }">
              {{ partialGames.find(g => g.id == game.parentGameId).name }}
            </router-link></li>
        </ul>
      </div>
      <!-- List authors -->
      <div v-if="people">
        <h3>Auteurs :</h3>
        <ul>
          <li v-for="authorId in game.authors" :key="authorId">
            {{ people.find(person => person.id == authorId).name }}
            <a href="#" v-if="user?.admin">
              <span class="material-symbols-outlined">delete</span>
            </a>
          </li>
          <li v-if="game.authors.length === 0">
            <em>Aucun auteur</em>
          </li>
          <li v-if="user?.admin">
            <a href="#">
              <span class="material-symbols-outlined">add</span>
              Ajouter un auteur
            </a>
          </li>
        </ul>
      </div>
      <!-- List illustrators -->
      <div v-if="people">
        <h3>Illustrateurs :</h3>
        <ul>
          <li v-for="illustratorId in game.illustrators" :key="illustratorId">
            {{ people.find(person => person.id == illustratorId).name }}
            <a href="#" v-if="user?.admin">
              <span class="material-symbols-outlined">delete</span>
            </a>
          </li>
          <li v-if="game.illustrators.length === 0">
            <em>Aucun illustrateur</em>
          </li>
          <li v-if="user?.admin">
            <a href="#">
              <span class="material-symbols-outlined">add</span>
              Ajouter un illustrateur
            </a>
          </li>
        </ul>
      </div>
      <!-- List themes -->
      <div v-if="themes">
        <h3>Thèmes :</h3>
        <ul>
          <li v-for="themeId in game.themes" :key="themeId">
            {{ themes.find(theme => theme.id == themeId).name }}
            <a href="#" v-if="user?.admin">
              <span class="material-symbols-outlined">delete</span>
            </a>
          </li>
          <li v-if="game.themes.length === 0">
            <em>Aucun thème</em>
          </li>
          <li v-if="user?.admin">
            <a href="#">
              <span class="material-symbols-outlined">add</span>
              Ajouter un thème
            </a>
          </li>
        </ul>
      </div>
      <!-- List mechanics -->
      <div v-if="mechanics">
        <h3>Mécaniques :</h3>
        <ul>
          <li v-for="mechanicId in game.mechanics" :key="mechanicId">
            {{ mechanics.find(mechanic => mechanic.id == mechanicId).name }}
            <a href="#" v-if="user?.admin">
              <span class="material-symbols-outlined">delete</span>
            </a>
          </li>
          <li v-if="game.mechanics.length === 0">
            <em>Aucune mécanique</em>
          </li>
          <li v-if="user?.admin">
            <a href="#">
              <span class="material-symbols-outlined">add</span>
              Ajouter une mécanique
            </a>
          </li>
        </ul>
      </div>

      <!-- List reviews -->
      <div>
        <h3>Critiques :</h3>
        <review-card v-for="review in game.reviews" :key="review.id" :review="review" :refresh="fetchGame"></review-card>
      </div>
    </div>
  </div>
</template>
  
<script>
import api from '@/api';
import { mapState } from 'vuex';
import ReviewCard from '@/components/ReviewCard.vue';

export default {
  name: 'GameView',
  computed: {
    ...mapState([
      'user',
      'partialGames',
      'editors',
      'types',
      'people',
      'themes',
      'mechanics'
    ])
  },
  watch: {
    $route() {
      if (this.$route.params.id && this.$route.name === 'game') {
        this.fetchGame();
      }
    }
  },
  data() {
    return {
      game: undefined,
    }
  },
  methods: {
    async fetchGame() {
      api.games.get(this.$route.params.id)
        .then(game => this.game = game.data)
        .catch(err => console.error(err))
    },
    dateString(str) {
      const date = new Date(str);
      const year = date.getFullYear();
      const month = date.getMonth() + 1;
      const day = date.getDate();

      return `${('0' + day).slice(-2)}/${('0' + month).slice(-2)}/${year}`;
    },
  },
  created() {
    this.fetchGame();
  },
  components: {
    ReviewCard
  }
}
</script>
  

<style lang="scss" scoped>
h3 {
  margin-bottom: 0;
}

ul {
  margin-top: 6px;

  li {
    margin-bottom: 4px;
  }
}
</style>