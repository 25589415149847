<template>
  <div class="page">
    <header>
      <div class="horizontal-container">
        <h2>Critiques</h2>
        <div class="horizontal-container">
          <div class="dropdown">
            <button class="dropbtn">
              <span class="material-symbols-outlined">{{ orders.find(f => f.id ===
                  pagination.orderBy)?.icon
              }}</span>
              {{ orders.find(f => f.id === pagination.orderBy)?.name }} ▼
            </button>
            <div class="dropdown-content">
              <a v-for="filter of orders" :key="filter.id" @click="pagination.orderBy = filter.id">
                <span class="material-symbols-outlined">{{ filter.icon }}</span>
                {{ filter.name }}
              </a>
            </div>
          </div>
          <div class="dropdown" style="margin-left: 8px;">
            <button class="dropbtn">
              <span class="material-symbols-outlined">format_list_numbered</span>{{ pagination.limit }} résultats par
              page ▼
            </button>
            <div class="dropdown-content">
              <a v-for="nb in [10, 20, 50, 100]" :key="nb"
                @click="(pagination = { ...pagination, limit: nb, page: 1 })">
                {{ nb }} résultats par page
              </a>
            </div>
          </div>
        </div>
      </div>
      <pre class="sql-request" v-if="pagination.orderBy == 'date'"><code>SELECT
    Games.name as gameName, Games.picture as gamePicture,
    Players.pseudo, Players.firstName, Players.lastName, Reviews.*,
    (SELECT count(*) FROM ReviewRatings WHERE ReviewRatings.reviewId = Reviews.id and helpful = 1) AS likes,
    (SELECT count(*) FROM ReviewRatings WHERE ReviewRatings.reviewId = Reviews.id and helpful = 0) AS dislikes
FROM Reviews
INNER JOIN Players ON Players.id=Reviews.playerId
INNER JOIN Games ON Games.id = Reviews.gameId
ORDER BY publicationDate DESC
LIMIT {{ pagination.limit }} OFFSET {{ (pagination.page - 1) * pagination.limit }};</code></pre>
      <pre class="sql-request" v-if="pagination.orderBy == 'reaction'"><code>SELECT
    Games.name as gameName, Games.picture as gamePicture,
    Players.pseudo, Players.firstName, Players.lastName, Reviews.*, COUNT(*) as countMax,
    (SELECT count(*) FROM ReviewRatings WHERE ReviewRatings.reviewId = Reviews.id and helpful = 1) AS likes,
    (SELECT count(*) FROM ReviewRatings WHERE ReviewRatings.reviewId = Reviews.id and helpful = 0) AS dislikes
FROM Reviews
INNER JOIN ReviewRatings ON Reviews.id=ReviewRatings.reviewId
INNER JOIN Players ON Players.id=Reviews.playerId
INNER JOIN Games ON Games.id = Reviews.gameId
GROUP BY id
ORDER BY countMax DESC
LIMIT {{ pagination.limit }} OFFSET {{ (pagination.page - 1) * pagination.limit }};</code></pre>
      <pre class="sql-request" v-if="pagination.orderBy == 'confidence'"><code>SELECT
    Games.name as gameName, Games.picture as gamePicture,
    Players.pseudo, Players.firstName, Players.lastName, Reviews.*,
    ((1+COUNT(IF(helpful=1,1,NULL)))/(1+COUNT(IF(helpful=0,1,NULL)))) AS confidenceIndex,
    (SELECT count(*) FROM ReviewRatings WHERE ReviewRatings.reviewId = Reviews.id and helpful = 1) AS likes,
    (SELECT count(*) FROM ReviewRatings WHERE ReviewRatings.reviewId = Reviews.id and helpful = 0) AS dislikes
FROM Reviews
LEFT OUTER JOIN ReviewRatings ON Reviews.id=ReviewRatings.reviewId
INNER JOIN Players ON Players.id=Reviews.playerId
INNER JOIN Games ON Games.id = Reviews.gameId
GROUP BY id
ORDER BY confidenceIndex DESC
LIMIT {{ pagination.limit }} OFFSET {{ (pagination.page - 1) * pagination.limit }};</code></pre>
    </header>
    <LudoteirbPagination :max-page="(stats.nbReviews / pagination.limit)" v-model="pagination.page" v-if="stats" />
    <div v-if="reviews">
      <review-card v-for="review in reviews" :key="review.id" :review="review" :refresh="fetchReviews"
        :deleteaction="remove" />
    </div>
    <LudoteirbModal title="Supprimer une critique" icon="delete" ref="deleteReviewModal"
      :confirm-callback="deleteReview" confirm-text="Supprimer">
      <p>Êtes-vous sûr de vouloir supprimer cette critique ?</p>
      <code class="sql-request">DELETE FROM Reviews WHERE id = {{ reviewToDelete?.id }};</code>
    </LudoteirbModal>
  </div>
</template>
  
<script>
import api from '@/api';
import { mapState } from 'vuex';
import LudoteirbPagination from '@/components/LudoteirbPagination.vue';
import LudoteirbModal from '@/components/LudoteirbModal.vue';
import ReviewCard from '@/components/ReviewCard.vue';

export default {
  name: 'ReviewsView',
  computed: {
    ...mapState([
      'user',
      'stats'
    ])
  },
  data() {
    return {
      reviews: undefined,
      orders: [
        {
          id: "date",
          name: "Date de publication",
          icon: "date_range",
        },
        {
          id: "reaction",
          name: "Nombre de réactions",
          icon: "thumb_up",
        },
        {
          id: "confidence",
          name: "Indice de confiance",
          icon: "verified_user",
        }
      ],
      pagination: {
        orderBy: 'date',
        limit: 10,
        page: 1,
      },
      reviewToDelete: undefined,
    }
  },
  watch: {
    pagination: {
      handler() {
        this.fetchReviews();
      },
      deep: true,
    },
  },
  methods: {
    remove(review) {
      this.reviewToDelete = review;
      this.$refs.deleteReviewModal.show();
    },
    deleteReview() {
      api.reviews.delete(this.reviewToDelete.id)
        .then(() => {
          // Tout s'est bien passé, on ferme la modale
          this.$refs.deleteReviewModal.hide();

          // On affiche un message de succès
          this.$notify({
            type: 'success',
            title: 'Succès',
            text: `La critique a bien été supprimée`
          });

          // On recharge la liste des critiques
          this.fetchReviews();
        })
        .catch((error) => {
          // Une erreur s'est produite, on affiche un message d'erreur
          this.$notify({
            title: 'Erreur lors de la suppression',
            text: error.message,
            type: 'error'
          });
        })
    },
    fetchReviews() {
      api.reviews.getOrdered(this.pagination.orderBy, this.pagination.limit, this.pagination.page)
        .then((reviews) => this.reviews = reviews.data)
        .catch((err) => {
          console.error(err);
          this.reviews = [];
        });
    },
  },
  created() {
    this.fetchReviews();
  },
  components: {
    LudoteirbPagination,
    LudoteirbModal,
    ReviewCard,
  },
}
</script>

<style scoped>
li {
  list-style-type: none;
}

a {
  color: black;
  text-decoration: none;
}
</style>
  