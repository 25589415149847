<template>
  <div class="page">
    <header>
      <h2>Jeux</h2>
      <div class="input-container">
        <span class="material-symbols-outlined">search</span>
        <input type="text" placeholder="Rechercher un jeu..." v-model="searchFilter" />
      </div>
      <div>
        <div class="dropdown">
          <button class="dropbtn" style="border-radius: 8px 0 0 8px">
            <span class="material-symbols-outlined">{{ orderByFilters.find(f => f.id ===
                orderBy)?.icon
            }}</span>
            {{ orderByFilters.find(f => f.id === orderBy)?.name }}
          </button>
          <div class="dropdown-content">
            <a v-for="filter of orderByFilters" :key="filter.id"
              @click="$router.push({ query: { orderBy: filter.id + (orderByAsc ? ',asc' : ',desc'), type: typeFilter ?? undefined } })">
              <span class="material-symbols-outlined">{{ filter.icon }}</span>
              {{ filter.name }}
            </a>
          </div>
        </div>
        <div class="dropdown">
          <button class="dropbtn" style="padding: 10px 8px; border-radius: 0 8px 8px 0; border-left: 2px solid #ad16a3"
            @click="$router.push({ query: { orderBy: orderBy + (orderByAsc ? ',desc' : ',asc'), type: typeFilter ?? undefined } })">
            <span class="material-symbols-outlined without-text">{{ orderByAsc ?
                'expand_less' : 'expand_more'
            }}</span>
          </button>
          <div class="dropdown-content">
            <a @click="$router.push({ query: { orderBy: orderBy + ',asc', type: typeFilter ?? undefined } })">
              <span class="material-symbols-outlined without-text">expand_less</span>
              Croissant
            </a>
            <a @click="$router.push({ query: { orderBy: orderBy + ',desc', type: typeFilter ?? undefined } })">
              <span class="material-symbols-outlined without-text">expand_more</span>
              Décroissant
            </a>
          </div>
        </div>
      </div>
      <div class="dropdown">
        <button class="dropbtn">
          <span class="material-symbols-outlined">{{ types && typeFilter ? types.find(t => t.id ===
              typeFilter)?.icon : 'category'
          }}</span>
          {{ types && typeFilter ? types.find(t => t.id ===
              typeFilter)?.name : 'Filtrer par type'
          }} ▼
        </button>
        <div class="dropdown-content">
          <a @click="$router.push({ query: { orderBy: orderBy + (orderByAsc ? ',asc' : ',desc'), type: undefined } })">
            <span class="material-symbols-outlined">apps</span>
            Tous les types
          </a>
          <a v-for="gameType in types" :key="gameType.id"
            @click="$router.push({ query: { orderBy: orderBy + (orderByAsc ? ',asc' : ',desc'), type: gameType.id } })">
            <span class="material-symbols-outlined">{{ gameType.icon }}</span>
            {{ gameType.name }}
          </a>
        </div>
      </div>
    </header>
    <LudoteirbPagination :max-page="(games.length / pagination.limit)" v-model="pagination.page" v-if="games" />
    <transition-group name="list-complete" tag="div" class="cards" v-if="(games && types && editors)">
      <router-link :to="{ name: 'game', params: { id: game.id } }" class="card" v-for="game in gamesPagined"
        :key="game.id">
        <div class="ribbon" :style="{ '--color': colors[game.typeId % colors.length] }">
          <span>{{ types.find(t => t.id === game.typeId)?.name }}</span>
        </div>
        <div class="card-actions" v-if="user">
          <button class="btn btn-outline btn-icon" @click.prevent="remove(game)" v-if="user.admin">
            <span class="material-symbols-outlined without-text">delete</span>
          </button>
        </div>
        <div class="card-image">
          <img :src="game.picture" :alt="'Photo de ' + game.name" />
        </div>
        <div class="card-content">
          <h3>{{ game.name }}</h3>
          <h5>{{ editors.find(editor => editor.id === game.editorId).name }}</h5>
          <p class="summarize">{{ game.description }}</p>
          <div class="card-footer">
            <div class="card-footer-item">
              <span class="material-symbols-outlined">group</span>
              {{ game.minPlayers }}{{ game.maxPlayers != game.minPlayers ? '-' + game.maxPlayers :
                  ''
              }}
            </div>
            <div class="card-footer-item">
              <span class="material-symbols-outlined">schedule</span> {{ game.duration }} min
            </div>
          </div>
        </div>
      </router-link>
    </transition-group>
    <LudoteirbModal title="Ajouter un jeu" icon="add" ref="addGameModal" :cancel-callback="clearForm"
      :confirm-callback="createGame">
      <div class="form">
        <LudoteirbTextInput id="gameName" icon="label" label="Nom" placeholder="Entrez un nom" v-model="newGame.name" />
        <LudoteirbTextInput id="gameDescription" icon="description" label="Description"
          placeholder="Entrez une description" multiline v-model="newGame.description" />
        <LudoteirbTextInput id="gamePicture" icon="link" label="Image" placeholder="Entrez un lien d'image"
          v-model="newGame.picture" />
        <LudoteirbTextInput id="gameRelaseDate" type="date" icon="calendar_month" label="Date de sortie"
          placeholder="Entrez une date" v-model="newGame.releaseDate" />
        <LudoteirbNumberInput id="gameDuration" icon="timer" label="Durée de partie" text="minutes" :max="9999"
          v-model="newGame.duration" />
        <LudoteirbNumberInput id="gameMinPlayers" icon="person_remove" label="Joueurs minimum" text="joueurs" :max="99"
          v-model="newGame.minPlayers" />
        <LudoteirbNumberInput id="gameMaxPlayers" icon="person_add" label="Joueurs maximum" text="joueurs" :max="99"
          v-model="newGame.maxPlayers" />
        <LudoteirbSelectInput id="gameTypeId" icon="category" label="Type de jeu" placeholder="Choisissez un type"
          :data="types" v-model="newGame.typeId" />
        <LudoteirbSelectInput id="gameEditorId" icon="business" label="Éditeur" placeholder="Choisissez un éditeur"
          :data="editors" v-model="newGame.editorId" />
        <label for="gameExtension">Extension de</label>
        <div class="horizontalContainer">
          <input type="checkbox" id="gameExtension" v-model="newGame.isExtension" />
          <div class="input-container" v-if="newGame.isExtension">
            <span class="material-symbols-outlined">casino</span>
            <div class="select">
              <select id="gameParentGameId" required v-model="newGame.parentGameId">
                <option value="" disabled selected>- Choisissez un jeu -</option>
                <option v-for="game of games" :value="game.id" :key="game.id">{{ game.name }}
                </option>
              </select>
              <div class="select_arrow">
              </div>
            </div>
          </div>
          <button class="btn btn-icon" v-if="newGame.isExtension" @click="$store.dispatch('loadPartialGames')">
            <span class="material-symbols-outlined without-text">refresh</span>
          </button>
        </div>
      </div>
      <code
        class="sql-request">INSERT INTO Games (name, description, picture, releaseDate, duration, minPlayers, maxPlayers, parentGameId, editorId, typeId) VALUES ('{{ newGame.name.replace(/'/g, "''") }}', '{{ newGame.description.replace(/'/g, "''") }}', '{{ newGame.picture }}', '{{ newGame.releaseDate }}', {{ newGame.duration }}, {{ newGame.minPlayers }}, {{ newGame.maxPlayers }}, {{ newGame.parentGameId != "" ? newGame.parentGameId : "null" }}, {{ newGame.editorId }}, {{ newGame.typeId }});</code>
    </LudoteirbModal>
    <LudoteirbModal title="Supprimer un jeu" icon="delete" ref="deleteGameModal" :confirm-callback="deleteGame"
      confirm-text="Supprimer">
      <p>Êtes-vous sûr de vouloir supprimer le jeu <strong>{{ gameToDelete?.name }}</strong>&nbsp;?</p>
      <p>Cela supprimera également toutes les données associées à ce jeu, telles que les critiques.</p>
      <code class="sql-request">DELETE FROM Games WHERE id = {{ gameToDelete?.id }};</code>
    </LudoteirbModal>
    <button class="fab" @click="addGameModal()" v-if="user?.admin">
      <span class="material-symbols-outlined without-text">add</span>
    </button>
  </div>
</template>

<script>
import LudoteirbNumberInput from '@/components/LudoteirbNumberInput.vue'
import LudoteirbTextInput from '@/components/LudoteirbTextInput.vue';
import LudoteirbSelectInput from '@/components/LudoteirbSelectInput.vue';
import LudoteirbModal from '@/components/LudoteirbModal.vue';
import LudoteirbPagination from '@/components/LudoteirbPagination.vue';
import { mapState } from 'vuex'
import api from '@/api';

const NEW_GAME = {
  name: "",
  description: "",
  picture: "",
  releaseDate: "",
  duration: 0,
  minPlayers: 0,
  maxPlayers: 0,
  typeId: "",
  editorId: "",
  isExtension: false,
  parentGameId: "",
};

export default {
  name: "GamesView",
  computed: {
    gamesPagined() {
      let games = this.games
        .filter((game) => {
          return (this.typeFilter === null || game.typeId === this.typeFilter)
            && (this.searchFilter === "" || (game.name.toLowerCase().includes(this.searchFilter.toLowerCase()) || game.description.toLowerCase().includes(this.searchFilter.toLowerCase())));
        })
        .sort((a, b) => {
          if (this.orderBy === "name") {
            return a.name.localeCompare(b.name);
          }
          else if (this.orderBy === "rating") {
            return b.rating - a.rating;
          }
          else if (this.orderBy === "date") {
            return a.releaseDate.localeCompare(b.releaseDate);
          }
          else if (this.orderBy === "duration") {
            return b.duration - a.duration;
          }
        });
      if (!this.orderByAsc) {
        games = games.reverse();
      }
      // eslint-disable-next-line
      this.pagination.maxPage = Math.ceil(games.length / this.resultsPerPage);
      // eslint-disable-next-line
      this.pagination.page = Math.max(1, Math.min(this.pagination.page, this.pagination.maxPage));
      return games.slice((this.pagination.page - 1) * this.resultsPerPage, this.pagination.page * this.resultsPerPage);
    },
    ...mapState([
      "colors",
      "editors",
      "types",
      "mechanics",
      "themes",
      "stats",
      "user"
    ])
  },
  watch: {
    "$route.query"(query) {
      this.updateQuery(query);
    }
  },
  data() {
    return {
      games: undefined,
      orderByFilters: [
        {
          id: "name",
          name: "Trier par nom",
          icon: "sort_by_alpha"
        },
        {
          id: "date",
          name: "Trier par date de sortie",
          icon: "date_range"
        },
        {
          id: "duration",
          name: "Trier par durée de partie",
          icon: "timer"
        }
      ],
      orderBy: "name",
      orderByAsc: true,
      resultsPerPage: 24,
      typeFilter: null,
      pagination: {
        limit: 24,
        page: 1
      },
      searchFilter: "",
      newGame: { ...NEW_GAME },
      gameToDelete: null,
    };
  },
  methods: {
    clearForm() {
      this.newGame = { ...NEW_GAME };
    },
    addGameModal() {
      this.$refs.addGameModal.show();
    },
    createGame() {
      api.games.create(this.newGame)
        .then((game) => {
          // Tout s'est bien passé, on ferme la modale
          this.$refs.addGameModal.hide();

          // On affiche un message de succès
          this.$notify({
            type: 'success',
            title: 'Succès',
            text: `Le jeu '${game.data.name}'' a bien été créé.`
          });

          // On réinitialise les données du formulaire
          this.clearForm();

          // On recharge la liste des jeux
          this.$store.dispatch('loadPartialGames');

          // On redirige vers la page du jeu
          this.$router.push({ name: 'game', params: { id: game.data.id } });
        })
        .catch((error) => {
          // Une erreur s'est produite, on affiche un message d'erreur
          this.$notify({
            title: 'Erreur lors de la création',
            text: error.message,
            type: 'error',
          });
        });
    },
    remove(game) {
      this.gameToDelete = game;
      this.$refs.deleteGameModal.show();
    },
    deleteGame() {
      api.games.delete(this.gameToDelete.id)
        .then(() => {
          // Tout s'est bien passé, on ferme la modale
          this.$refs.deleteGameModal.hide();

          // On affiche un message de succès
          this.$notify({
            type: 'success',
            title: 'Succès',
            text: `Le jeu '${this.gameToDelete.name}'' a bien été supprimé.`
          });

          // On recharge la liste des jeux
          this.$store.dispatch('loadPartialGames');
          this.$store.dispatch('loadStats');
          this.fetchGames();
        })
        .catch((error) => {
          // Une erreur s'est produite, on affiche un message d'erreur
          this.$notify({
            title: 'Erreur lors de la suppression',
            text: error.message,
            type: 'error',
          });
        });
    },
    updateQuery(query) {
      if (query.type) {
        this.typeFilter = parseInt(query.type);
      }
      else {
        this.typeFilter = null;
      }
      if (query.orderBy) {
        const [orderBy, direction] = query.orderBy.split(",");
        this.orderBy = orderBy;
        this.orderByAsc = direction === "asc";
      }
      else {
        this.orderBy = "name";
      }
    },
    fetchGames() {
      api.games.list().then((games) => {
        this.games = games.data;
      });
    }
  },
  mounted() {
    this.updateQuery(this.$route.query);
  },
  created() {
    this.fetchGames();
  },
  components: {
    LudoteirbNumberInput,
    LudoteirbTextInput,
    LudoteirbSelectInput,
    LudoteirbModal,
    LudoteirbPagination
  }
}
</script>

<style lang="scss" scoped>
header {
  display: grid;
  grid-template-columns: 1fr auto auto auto;
  grid-template-rows: 1fr;
  align-items: center;
  grid-gap: 10px;
}


@media screen and (min-width: 571px) and (max-width: 1060px) {
  header {
    grid-template-columns: auto auto;
    grid-template-rows: 1fr 1fr 1fr;
  }

  header .input-container,
  header h2 {
    grid-column: 1 / 3;
  }

  header div:nth-last-child(2) {
    justify-self: end;
  }
}

@media screen and (max-width: 570px) {
  header {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }

  header .input-container,
  header h2 {
    grid-column: 1;
  }

  header div {
    justify-self: center;
  }
}
</style>