const request = async (endpoint) => {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json');

    return new Promise((resolve, reject) => {
        return fetch(endpoint.resource, {
            method: endpoint.method,
            headers,
            body: endpoint.body ? JSON.stringify(endpoint.body) : undefined,
        }).then(async (response) => {
            const responseData = await response.json();
            if (responseData.status === "success") {
                resolve(responseData);
            } else {
                reject({
                    type: "API Error",
                    message: responseData.message,
                    code: responseData.code,
                    status: response.status,
                });
            }
        }).catch((error) => {
            reject({
                type: "API Wrapper Error",
                message: error.message,
            })
        });
    });
};

const get = function (resource) {
    return request({
        resource,
        method: "GET",
    });
}

const post = function (resource, body) {
    return request({
        resource,
        method: "POST",
        body,
    });
}

const put = function (resource, body) {
    return request({
        resource,
        method: "PUT",
        body,
    });
}

const remove = function (resource) {
    return request({
        resource,
        method: "DELETE",
    });
}

const routes = {
    stats: () => get("/api/stats"),
    auth: {
        login: (pseudo, password) => post("/api/auth/login", { pseudo, password }),
        register: (pseudo, email, firstName, lastName) => post("/api/auth/register", { pseudo, email, firstName, lastName }),
        user: () => get("/api/auth/user"),
        logout: () => get("/api/auth/logout"),
        addFavoriteTheme: (themeId) => post(`/api/auth/user/favoriteThemes/${themeId}`),
        removeFavoriteTheme: (themeId) => remove(`/api/auth/user/favoriteThemes/${themeId}`),
        addFavoriteMechanic: (mechanicId) => post(`/api/auth/user/favoriteMechanics/${mechanicId}`),
        removeFavoriteMechanic: (mechanicId) => remove(`/api/auth/user/favoriteMechanics/${mechanicId}`),
    },
    editors: {
        list: () => get("/api/editors"),
        get: (editorId) => get(`/api/editors/${editorId}`),
        create: (editor) => post("/api/editors", editor),
        update: (editorId, editor) => put(`/api/editors/${editorId}`, editor),
        delete: (editorId) => remove(`/api/editors/${editorId}`),
    },
    games: {
        list: () => get("/api/games"),
        partialList: () => get("/api/games/partial"),
        get: (gameId) => get(`/api/games/${gameId}`),
        create: (game) => post("/api/games", game),
        update: (gameId, game) => put(`/api/games/${gameId}`, game),
        delete: (gameId) => remove(`/api/games/${gameId}`),
    },
    mechanics: {
        list: () => get("/api/mechanics"),
        get: (mechanicId) => get(`/api/mechanics/${mechanicId}`),
        create: (mechanic) => post("/api/mechanics", mechanic),
        update: (mechanicId, mechanic) => put(`/api/mechanics/${mechanicId}`, mechanic),
        delete: (mechanicId) => remove(`/api/mechanics/${mechanicId}`),
    },
    themes: {
        list: () => get("/api/themes"),
        get: (themeId) => get(`/api/themes/${themeId}`),
        getGameReviewedOrderedByMechanics: (themeId) => get(`/api/themes/${themeId}/games`),
        create: (theme) => post("/api/themes", theme),
        update: (themeId, theme) => put(`/api/themes/${themeId}`, theme),
        delete: (themeId) => remove(`/api/themes/${themeId}`),
    },
    types: {
        list: () => get("/api/types"),
        get: (typeId) => get(`/api/types/${typeId}`),
        create: (type) => post("/api/types", type),
        update: (typeId, type) => put(`/api/types/${typeId}`, type),
        delete: (typeId) => remove(`/api/types/${typeId}`),
    },
    players: {
        list: () => get("/api/players"),
        getOrdered: (order, limit=10, page=1) => get(`/api/players/stats?orderBy=${order}&limit=${limit}&page=${page}`),
        get: (playerId) => get(`/api/players/${playerId}`),
        create: (player) => post("/api/players", player),
        update: (playerId, player) => put(`/api/players/${playerId}`, player),
        delete: (playerId) => remove(`/api/players/${playerId}`),
    },
    people: {
        list: () => get("/api/people"),
        get: (personId) => get(`/api/people/${personId}`),
        create: (person) => post("/api/people", person),
        update: (personId, person) => put(`/api/people/${personId}`, person),
        delete: (personId) => remove(`/api/people/${personId}`),
    },
    reviews: {
        list: () => get("/api/reviews"),
        getOrdered: (orderBy, limit=10, page=1) => get(`/api/reviews/stats?orderBy=${orderBy}&limit=${limit}&page=${page}`),
        get: (reviewId) => get(`/api/reviews/${reviewId}`),
        getLikes: (reviewId) => get(`/api/reviews/likes/${reviewId}`),
        getWithPlayerAndGame: (reviewId) => get(`/api/reviews/${reviewId}/withPlayerAndGame`),
        rate: (reviewId, helpful) => post(`/api/reviews/${reviewId}/ratingReview/${helpful}`),
        removeRating: (reviewId) => remove(`/api/reviews/${reviewId}/ratingReview`),
        create: (review) => post("/api/reviews", review),
        update: (reviewId, review) => put(`/api/reviews/${reviewId}`, review),
        delete: (reviewId) => remove(`/api/reviews/${reviewId}`),
    }
}
export default routes;
