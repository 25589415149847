<template>
    <div class="page">
        <header>
            <h2 v-if="themes"><router-link :to="{name: 'themes'}">Thèmes</router-link> <span class="material-symbols-outlined without-text">double_arrow</span> {{ this.themes.find(theme => theme.id ===
                    parseInt(this.$route.params.id)).name
            }}</h2>
        </header>
        <pre class="sql-request"><code>SELECT DISTINCT Mechanics.id as mechanicId, Mechanics.name as mechanicName, Games.*, COUNT(Games.id) as reviewsCount
FROM Games
INNER JOIN Reviews ON Reviews.gameId = Games.id
INNER JOIN GameThemes ON GameThemes.gameId=Games.id
INNER JOIN Themes ON Themes.id=GameThemes.themeId
INNER JOIN GameMechanics ON GameMechanics.gameId=Games.id
INNER JOIN Mechanics ON Mechanics.id=GameMechanics.mechanicId
WHERE Themes.id = {{ this.$route.params.id }}
GROUP BY Mechanics.id, Games.id
ORDER BY Mechanics.name ASC, Games.name ASC;</code></pre>
        <div v-for="mechanic in mechanics" :key="mechanic.id">
            <h3>{{ mechanic.name }}</h3>
            <div class="games">
                <div class="cards" v-if="(types && editors)">
                    <router-link :to="{ name: 'game', params: { id: game.id } }" class="card"
                        v-for="game in mechanic.games" :key="game.id">
                        <div class="ribbon" :style="{ '--color': colors[game.typeId % colors.length] }">
                            <span>{{ types.find(t => t.id == game.typeId)?.name }}</span>
                        </div>
                        <div class="card-image">
                            <img :src="game.picture" :alt="'Photo de ' + game.name" />
                        </div>
                        <div class="card-content">
                            <h3>{{ game.name }}</h3>
                            <h5>{{ editors.find(editor => editor.id == game.editorId).name }}</h5>
                            <p class="summarize">{{ game.description }}</p>
                            <div class="card-footer">
                                <div class="card-footer-item">
                                    <span class="material-symbols-outlined">comment</span> {{ game.reviewsCount }}
                                </div>
                                <div class="card-footer-item">
                                    <span class="material-symbols-outlined">group</span>
                                    {{ game.minPlayers }}{{ game.maxPlayers != game.minPlayers ? '-' + game.maxPlayers :
                                            ''
                                    }}
                                </div>
                                <div class="card-footer-item">
                                    <span class="material-symbols-outlined">schedule</span> {{ game.duration }} min
                                </div>
                            </div>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>
  
<script>
import api from '@/api';
import { mapState } from 'vuex';

export default {
    name: 'ThemeView',
    computed: {
        ...mapState([
            'types',
            'colors',
            'types',
            'editors',
            'themes',
        ])
    },
    data() {
        return {
            mechanics: undefined,
        }
    },
    methods: {
    },
    created() {
        api.themes.getGameReviewedOrderedByMechanics(this.$route.params.id)
            .then(games => {
                // Extract the mechanics from the games
                this.mechanics = games.data.reduce((acc, game) => {
                    if (!acc.find(m => m.id === game.mechanicId)) {
                        acc.push({
                            id: game.mechanicId,
                            name: game.mechanicName,
                            games: [],
                        });
                    }
                    return acc;
                }, []);

                // Add the games to the mechanics
                games.data.forEach(game => {
                    this.mechanics.find(m => m.id === game.mechanicId).games.push(game);
                });
            })
            .catch(err => console.error(err))
    }
}
</script>
  