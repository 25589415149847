<template>
  <div class="home">
    <div class="top">
      <h1>Ludot'eirb</h1>
      <p>
        Bienvenue sur le site de Ludot'eirb, le site<br />de référence et d'avis sur les jeux de société.
      </p>
    </div>
    <div class="numbers">
      <router-link class="number" to="/games">
        <span class="material-symbols-outlined">casino</span>
        <span class="value" v-if="stats">
          <number ref="number1" :from="1" :to="stats.nbGames" :duration="2" :delay="0.5" easing="Power1.easeOut" />
        </span>
        <span class="label">Jeux en<br />catalogue</span>
      </router-link>
      <router-link class="number" to="/players">
        <span class="material-symbols-outlined">group</span>
        <span class="value" v-if="stats">
          <number ref="number2" :from="1" :to="stats.nbPlayers" :duration="2" :delay="0.5" easing="Power1.easeOut" />
        </span>
        <span class="label">Joueurs<br />inscrits</span>
      </router-link>
      <router-link class="number" to="/reviews">
        <span class="material-symbols-outlined">star</span>
        <span class="value" v-if="stats">
          <number ref="number3" :from="1" :to="stats.nbReviews" :duration="2" :delay="0.5" easing="Power1.easeOut" />
        </span>
        <span class="label">Critiques<br />publiées</span>
      </router-link>
    </div>
    <div class="bottom"></div>
  </div>
</template>

<style lang="scss" scoped>
.home {
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto 1fr;

  .top {
    background-color: #c940ac;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    h1 {
      color: white;
      font-size: 6rem;
      margin: 0;
      animation: appearfromdown 0.5s ease-out;
    }

    @keyframes appearfromdown {
      0% {
        transform: translateY(50%);
        opacity: 0;
      }

      100% {
        transform: translateY(0);
        opacity: 1;
      }
    }

    p {
      color: white;
      font-size: 1.5rem;
      margin: 0;
      text-align: center;
      animation: appearfromfar 0.5s ease-out;
    }

    @keyframes appearfromfar {
      0% {
        transform: scale(0.5);
        opacity: 0;
      }

      100% {
        transform: scale(1);
        opacity: 1;
      }
    }

    @media screen and (max-width: 600px) {
      h1 {
        font-size: 16vw;
      }

      p {
        font-size: 4vw;
      }

    }
  }

  .numbers {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-image: url('@/assets/background.svg');
    background-size: 100% 100%;
    background-position: 0 0;

    .number {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 1rem 1rem;
      background-color: #fff;
      border-radius: 0.5rem;
      width: 10rem;
      height: 10rem;
      box-shadow: 0 0 0.5rem rgba(0, 0, 0, 0.25);

      text-decoration: none;
      color: black;
      transition: background-color 0.2s ease-out;

      opacity: 0;
      animation: appearfromdown 0.4s 0.2s ease-out forwards;

      &:nth-child(2) {
        animation-delay: 0.4s;
      }

      &:nth-child(3) {
        animation-delay: 0.6s;
      }

      &:hover {
        background-color: #f5f5f5;
      }

      .material-symbols-outlined {
        font-size: 3rem;
        line-height: 3rem !important;
        margin-right: 0;
      }

      .value {
        font-size: 2rem;
        font-weight: bold;
        margin: 0.2rem 0;
      }

      .label {
        font-size: 1rem;
        text-align: center;
      }
    }
  }
}
</style>

<script>
import { mapState } from 'vuex'

export default {
  name: 'HomeView',
  computed: mapState([
    'stats'
  ]),
}
</script>
