<template>
    <label :for="id">{{ label }}</label>
    <div class="input-container">
        <span class="material-symbols-outlined">{{ icon }}</span>
        <input :type="type" :placeholder="placeholder" @input="handleInput" :value="currentValue" v-bind="$attrs" :autofocus="autofocus" :disabled="disabled" v-if="!multiline" />
        <textarea :placeholder="placeholder" @input="handleInput" :value="currentValue" rows="3" v-bind="$attrs" :autofocus="autofocus" :disabled="disabled" v-else>
        </textarea>
    </div>
</template>

<script>

export default {
    name: 'LudoteirbTextInput',
    props: {
        id: {
            type: String,
            required: true
        },
        type: {
            type: String,
            default: 'text'
        },
        icon: {
            type: String,
            required: true
        },
        modelValue: {
            type: String,
            default: ''
        },
        label: {
            type: String,
            default: ''
        },
        placeholder: {
            type: String,
            default: ''
        },
        multiline: {
            type: Boolean,
            default: false
        },
        autofocus: {
            type: Boolean,
            default: false
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            currentValue: this.modelValue
        }
    },
    methods: {
        handleInput(event) {
            this.currentValue = event.target.value;
            this.$emit('update:modelValue', this.currentValue);
        },
    },
    watch: {
        modelValue(value) {
            this.currentValue = value;
        }
    }
}

</script>