<template>
    <div class="page">
        <header>
            <h2>Mécaniques</h2>
        </header>
        <pre class="sql-request"><code>SELECT * FROM Mechanics ORDER BY name ASC;</code></pre>
        <div class="cards" v-if="mechanics">
            <div class="card" v-for="mechanic in mechanics" :key="mechanic.id"
                :style="{ borderTop: '8px solid ' + colors[mechanic.id % colors.length] }">
                <div class="card-actions" v-if="user">
                    <button class="btn btn-outline btn-icon" @click="remove(mechanic)" v-if="user.admin">
                        <span class="material-symbols-outlined without-text">delete</span>
                    </button>
                    <button class="btn btn-icon" @click="like(mechanic)"
                        v-if="user.favoriteMechanics.find(favoriteMechanic => favoriteMechanic.id === mechanic.id)">
                        <span class="material-symbols-outlined without-text">favorite</span>
                    </button>
                    <button class="btn btn-outline btn-icon" @click="like(mechanic)" v-else>
                        <span class="material-symbols-outlined without-text">heart_plus</span>
                    </button>
                </div>
                <div class="card-content">
                    <h3>{{ mechanic.name }}</h3>
                    <p>{{ mechanic.description }}</p>
                </div>
            </div>
        </div>
        <LudoteirbModal title="Ajouter une mécanique" icon="add" ref="addMechanicModal" :cancel-callback="clearForm"
            :confirm-callback="createMechanic">
            <div class="form">
                <LudoteirbTextInput id="mechanicName" icon="label" label="Nom de la mécanique"
                    placeholder="Entrez un nom" v-model="newMechanic.name" />
                <LudoteirbTextInput id="mechanicDescription" icon="description" label="Description de la mécanique"
                    placeholder="Entrez une description" multiline v-model="newMechanic.description" />
            </div>
            <code class="sql-request">
                INSERT INTO Mechanics (name, description) VALUES ('{{ newMechanic.name.replace("'", "''") }}', '{{ newMechanic.description.replace("'", "''") }}');
            </code>
        </LudoteirbModal>
        <LudoteirbModal title="Supprimer une mécanique" icon="delete" ref="deleteMechanicModal"
            :confirm-callback="deleteMechanic" confirm-text="Supprimer">
            <p>Êtes-vous sûr de vouloir supprimer la mécanique <strong>{{ mechanicToDelete?.name }}</strong>&nbsp;?</p>
            <code class="sql-request">DELETE FROM Mechanics WHERE id = {{ mechanicToDelete?.id }};</code>
        </LudoteirbModal>
        <button class="fab" @click="add()" v-if="user?.admin">
            <span class="material-symbols-outlined without-text">add</span>
        </button>
    </div>
</template>
  
<script>
import api from '@/api';
import LudoteirbModal from '@/components/LudoteirbModal.vue';
import LudoteirbTextInput from '@/components/LudoteirbTextInput.vue';
import { mapState } from 'vuex';

const NEW_MECHANIC = {
    name: '',
    description: '',
};

export default {
    name: 'MechanicsView',
    computed: {
        ...mapState([
            'colors',
            'user',
            'mechanics',
        ])
    },
    data() {
        return {
            newMechanic: { ...NEW_MECHANIC },
            mechanicToDelete: null,
        }
    },
    methods: {
        clearForm() {
            this.newMechanic = { ...NEW_MECHANIC };
        },
        add() {
            this.$refs.addMechanicModal.show()
        },
        createMechanic() {
            api.mechanics.create(this.newMechanic)
                .then(() => {
                    // Tout s'est bien passé, on ferme la modale
                    this.$refs.addMechanicModal.hide();

                    // On affiche un message de succès
                    this.$notify({
                        type: 'success',
                        title: 'Succès',
                        text: `La mécanique '${this.newMechanic.name}' a bien été créée.`
                    });

                    // On réinitialise les données du formulaire
                    this.clearForm();

                    // On recharge la liste des mécaniques
                    this.$store.dispatch('loadMechanics');
                })
                .catch((error) => {
                    // Une erreur s'est produite, on affiche un message d'erreur
                    this.$notify({
                        title: 'Erreur lors de la création',
                        text: error.message,
                        type: 'error'
                    });
                });
        },
        remove(mechanic) {
            this.mechanicToDelete = mechanic;
            this.$refs.deleteMechanicModal.show();
        },
        deleteMechanic() {
            api.mechanics.delete(this.mechanicToDelete.id)
                .then(() => {
                    // Tout s'est bien passé, on ferme la modale
                    this.$refs.deleteMechanicModal.hide();

                    // On affiche un message de succès
                    this.$notify({
                        type: 'success',
                        title: 'Succès',
                        text: `La mécanique '${this.mechanicToDelete.name}' a bien été supprimée.`
                    });

                    // On recharge la liste des mécaniques
                    this.$store.dispatch('loadMechanics');
                })
                .catch((error) => {
                    // Une erreur s'est produite, on affiche un message d'erreur
                    this.$notify({
                        title: 'Erreur lors de la suppression',
                        text: error.message,
                        type: 'error'
                    });
                });
        },
        like(mechanic) {
            if (this.user.favoriteMechanics.find(favoriteMechanic => favoriteMechanic.id === mechanic.id)) {
                api.auth.removeFavoriteMechanic(mechanic.id)
                    .then(() => {
                        this.$notify({
                            type: 'success',
                            title: 'Succès',
                            text: `La mécanique '${mechanic.name}' a bien été retirée de vos favoris.`
                        });
                        this.$store.dispatch('removeFavoriteMechanic', mechanic);
                    })
                    .catch((error) => {
                        this.$notify({
                            title: 'Erreur lors de la suppression de la mécanique des favoris',
                            text: error.message,
                            type: 'error'
                        });
                    });
            } else {
                api.auth.addFavoriteMechanic(mechanic.id)
                    .then(() => {
                        this.$notify({
                            type: 'success',
                            title: 'Succès',
                            text: `La mécanique '${mechanic.name}' a bien été ajoutée à vos favoris.`
                        });
                        this.$store.dispatch('addFavoriteMechanic', mechanic);
                    })
                    .catch((error) => {
                        this.$notify({
                            title: 'Erreur lors de l\'ajout de la mécanique aux favoris',
                            text: error.message,
                            type: 'error'
                        });
                    });
            }
        },
    },
    components: {
        LudoteirbModal,
        LudoteirbTextInput
    }
}
</script>