<template>
    <router-link :to="{ 'name': 'review', 'params': { 'id': review.id } }" class="review-card"
        :class="{ 'with-game': review.gameName }">
        <div class="review-profile">
            <img :src="('https://avatars.dicebear.com/api/avataaars/' + review.pseudo + '.svg')" width="128"
                :alt="'Photo de profil du joueur ' + review.pseudo">
            <span class="name">{{ review.firstName }} {{ review.lastName }}</span>
            <span class="pseudo">@{{ review.pseudo }}</span>
        </div>
        <div class="review-content">
            <div class="review-value">
                <div><span class="material-symbols-outlined">star</span>
                    {{ review.value }}/20</div>
                <div>
                    <span class="material-symbols-outlined">person</span>
                    {{ review.playersCount }} joueurs
                </div>
                <div>{{ dateTimeString(review.publicationDate) }} <span class="material-symbols-outlined">event</span>
                </div>
            </div>
            <div class="review-comment">
                {{ review.comment }}
            </div>
            <div class="review-actions" v-if="user">
                <div class="horizontal-container" style="justify-content: center;">
                    <button class="btn btn-outline" @click.prevent="deleteaction(review)" v-if="(user.admin && deleteaction)">
                        <span class="material-symbols-outlined without-text">delete</span> Supprimer
                    </button>
                    <button class="btn" @click.prevent="likeOrDislike(review.id, 1)"
                        v-if="(user.pseudo != review.pseudo)"
                        :class="{ 'btn-outline': !user.ratings.find(r => r.id == review.id) || (user.ratings.find(r => r.id == review.id) && user.ratings.find(r => r.id == review.id).helpful == '0') }">
                        <span class="material-symbols-outlined without-text">thumb_up</span>
                        {{ review.likes }}
                    </button>
                    <button class="btn" @click.prevent="likeOrDislike(review.id, 0)"
                        v-if="(user.pseudo != review.pseudo)"
                        :class="{ 'btn-outline': !user.ratings.find(r => r.id == review.id) || (user.ratings.find(r => r.id == review.id) && user.ratings.find(r => r.id == review.id).helpful == '1') }">
                        <span class="material-symbols-outlined without-text">thumb_down</span>
                        {{ review.dislikes }}
                    </button>
                </div>
            </div>
        </div>
        <div class="review-game" v-if="review.gameName">
            <img :src="review.gamePicture" width="128" :alt="'Photo du jeu ' + review.gameName">
            <span class="name">{{ review.gameName }}</span>
        </div>
    </router-link>
</template>

<script>
import api from '@/api';
import { mapState } from 'vuex';

export default {
    name: 'ReviewCard',
    computed: {
        ...mapState([
            'user',
        ])
    },
    props: {
        review: {
            type: Object,
            default: null
        },
        refresh: {
            type: Function,
            default: null
        },
        deleteaction: {
            type: Function,
            default: null
        }
    },
    data() {
        return {

        }
    },
    methods: {
        dateTimeString(str) {
            const date = new Date(str);
            const year = date.getFullYear();
            const month = date.getMonth() + 1;
            const day = date.getDate();
            const hours = date.getHours();
            const minutes = date.getMinutes();

            return `le ${('0' + day).slice(-2)}/${('0' + month).slice(-2)}/${year} à ${('0' + hours).slice(-2)}:${('0' + minutes).slice(-2)}`;
        },
        likeOrDislike(reviewId, helpful) {
            const previousRating = this.user.ratings.find(r => r.id == reviewId);
            if (previousRating) {
                if (previousRating.helpful == helpful) {
                    this.deleteLikeOrDislike(reviewId);
                } else {
                    api.reviews.rate(reviewId, helpful)
                        .then(() => {
                            this.$store.dispatch('loadUser');
                            if (this.refresh) this.refresh();
                            this.$notify({
                                type: 'success',
                                title: 'Succès',
                                text: `La reaction a bien été ajouté`
                            });
                        })
                        .catch((error) => {
                            this.$notify({
                                title: 'Erreur lors de l ajout de la reaction',
                                text: error.message,
                                type: 'error'
                            });
                        });
                }
            } else {
                api.reviews.rate(reviewId, helpful)
                    .then(() => {
                        this.$store.dispatch('loadUser');
                        if (this.refresh) this.refresh();
                        this.$notify({
                            type: 'success',
                            title: 'Succès',
                            text: `La reaction a bien été ajouté`
                        });
                    })
                    .catch((error) => {
                        this.$notify({
                            title: 'Erreur lors de l ajout de la reaction',
                            text: error.message,
                            type: 'error'
                        });
                    });
            }
        },
        deleteLikeOrDislike(reviewId) {
            api.reviews.removeRating(reviewId)
                .then(() => {
                    this.$store.dispatch('loadUser');
                    if (this.refresh) this.refresh();
                    this.$notify({
                        type: 'success',
                        title: 'Succès',
                        text: `La reaction a bien été supprimé`
                    });
                })
                .catch((error) => {
                    this.$notify({
                        title: 'Erreur lors de la suppression de la reaction',
                        text: error.message,
                        type: 'error'
                    });
                });
        }
    }
}
</script>

<style lang="scss" scoped>
.review-card {
    position: relative;
    margin-bottom: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    overflow: hidden;
    text-decoration: none;
    color: black;

    display: grid;
    grid-template-columns: 180px 1fr;
    grid-template-rows: 1fr;

    &.with-game {
        grid-template-columns: 180px 1fr 180px;
    }

    .review-profile,
    .review-game {
        background-color: #eee;
        padding: 16px;
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 100px;
            height: 100px;
            margin-bottom: 8px;
        }

        .name {
            font-weight: bold;
            margin-bottom: 4px;
            font-size: 0.9em;
        }

        .pseudo {
            font-style: italic;
            font-size: 0.8em;
        }
    }

    .review-profile img {
        border-radius: 50%;
    }

    .review-content {
        padding: 16px;
        display: grid;
        grid-template-rows: 24px 1fr 32px;

        .review-value {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            justify-content: space-between;

            span.material-symbols-outlined {
                margin-right: 4px;
                vertical-align: sub;
            }
        }

        .review-comment {
            font-style: italic;
        }
    }
}
</style>